import { Navbar, Nav, Container, NavDropdown, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../assets/styling/header.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QuizHomeHeader from "./QuizHomeHeader";
import { pathsToCheck } from "../utils/helpful";

const Header = () => {
  const location = useLocation();
  const [showSub, setShowSub] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const [stripped, setStripped] = useState(false);
  const [ty_page, setTY] = useState(false);

  useEffect(() => {
    if (location.pathname === "/thank-you") setTY(true);
    //if logged in dont show subscribe - show profile
    if (
      location.pathname === "/sublanding" ||
      location.pathname === "/thank-you"
    ) {
      setShowSub(false);
    } else {
      setShowSub(true);
    }
    if (location.pathname.includes("/profile")) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    if (pathsToCheck.some((path) => location.pathname === path)) {
      setStripped(true);
    } else {
      setStripped(false);
    }
  }, [location]);

  if (!showHeader) return null;
  if (stripped) return <QuizHomeHeader pathname={location.pathname} />;
  return (
    <header>
      <Navbar className="tbb-nav" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src={"https://d2oa23hr7q6t96.cloudfront.net/base/tbbheader.jpg"}
                height="40"
                alt="The Boss Brief logo"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {/* on Thank you page choose to show Leadership Newsletter vs Latest & About */}
              {ty_page ? (
                <>
                  <LinkContainer to="/leadership-newsletter">
                    <Nav.Link className="tbb-nav-link">
                      Leadership Newsletter
                    </Nav.Link>
                  </LinkContainer>
                </>
              ) : (
                <>
                  <LinkContainer to="/latest">
                    <Nav.Link className="tbb-nav-link">
                      Latest Newsletter
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <Nav.Link className="tbb-nav-link">About</Nav.Link>
                  </LinkContainer>
                </>
              )}

              {/* Search 'Reinstate Archive' to find all */}
              {/* <LinkContainer to="/archive">
                <Nav.Link className="tbb-nav-link">Archive</Nav.Link>
              </LinkContainer> */}

              {showSub ? (
                userInfo && ["premium", "access"].includes(userInfo.type) ? (
                  <LinkContainer to="/profile">
                    <Nav.Link id="account-nav-link">
                      {" "}
                      <ManageAccountsIcon /> Account
                    </Nav.Link>
                  </LinkContainer>
                ) : (
                  <LinkContainer to="/sublanding">
                    <Nav.Link id="sub-nav-link">Subscribe</Nav.Link>
                  </LinkContainer>
                )
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
