import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreferences,
  useGetPrefsMutation,
} from "../../slices/usersApiSlice";
import AppHadError from "../../appwide/AppHadError";
import Loader from "../Loader";
import axios from "axios";
import {
  leader_preference_list,
  leader_preference,
} from "../../utils/leaderImgs";
import "../../assets/styling/profile.css";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const LeaderPrefs = ({ show_type }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.profile);
  const [viewing, setViewing] = useState({});
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      setLoading(true);
      loadPrefs();
    } else {
      console.log("no user");
    }
  }, []);

  const loadPrefs = async () => {
    setLoading(true);
    try {
      //check redux first
      if (account.email) {
        setViewing(account);
        setLoading(false);
      } else {
        //go to db
        const res = await axios.post("/api/users/leader_prefs", {
          id: userInfo._id,
        });
        setViewing(res.data);
        if (res.err || res.data.err) setErr(true);

        setLoading(false);
        dispatch(setPreferences(res.data));
      }
    } catch (err) {
      console.log("err");
    }
  };

  const preferenceSetup = (pref) => {
    if (leader_preference_list.includes(pref)) {
      const pref_key = viewing[pref];
      const wording = leader_preference[pref].wording;
      if (leader_preference[pref] && leader_preference[pref][pref_key]) {
        return (
          <FormControl className="pref-box" key={pref}>
            <p className="pref-label">{wording}</p>
            <div
              style={{
                backgroundImage:
                  "url(" + leader_preference[pref][pref_key].img + ")",
              }}
              className="pref-image"
            ></div>

            <Select
              value={pref_key}
              className="pref-selection"
              onChange={(e) => handlePreferenceChange(pref, e.target.value)}
            >
              {Object.keys(leader_preference[pref]).map((key) => {
                if (key !== "wording") {
                  return (
                    <MenuItem key={key} value={key}>
                      <img
                        className="pref-image-menu"
                        src={leader_preference[pref][key].img}
                      />
                      {leader_preference[pref][key].sub}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
          </FormControl>
        );
      }
    }

    return null;
  };
  const preferenceSetupSimple = (pref) => {
    if (leader_preference_list.includes(pref)) {
      const pref_key = viewing[pref];
      const wording = leader_preference[pref].wording;

      if (leader_preference[pref] && leader_preference[pref][pref_key]) {
        const img = leader_preference[pref][pref_key].img;
        const sub_text = leader_preference[pref][pref_key].sub;

        return (
          <div className="pref-image-s" key={pref}>
            <img src={img} alt={sub_text || "Image"} />
            <div className="pref-block">
              <p className="pref-title">{wording}</p>
              <p className="pref-sub">{sub_text}</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="pref-image-s" key={pref}>
            <img src={""} alt="No image available" />
          </div>
        );
      }
    }
  };

  const handlePreferenceChange = async (pref, value) => {
    setViewing((prev) => ({
      ...prev,
      [pref]: value,
    }));
    // Optionally, can also dispatch an action to update the preferences in the store or backend
    dispatch(setPreferences({ ...viewing, [pref]: value }));
    const res = await axios.post("/api/users/leader_prefs", {
      id: userInfo._id,
      pref,
      value,
      update: true,
    });
  };

  if (loading) {
    return <Loader h={"15em"} m={"0"} w={"100%"} />;
  }

  return (
    <div>
      {err ? (
        <div>
          There was an error, please try reloading the page. If this message
          keeps popping up please contact us at{" "}
          <Link to="mailto:support@thebossbrief.com">
            support@thebossbrief.com
          </Link>
        </div>
      ) : show_type === "full" ? (
        <div
          style={{ margin: "auto auto 3em", maxWidth: "700px" }}
          className="leader-prefs"
        >
          <div>
            <p className="lead-pref-title">Configure Your Leader Persona</p>
          </div>
          <div>
            {Object.keys(viewing).map((preference) => {
              return preferenceSetup(preference);
            })}
          </div>
        </div>
      ) : (
        <div className="leader-prefs-simple">
          {Object.keys(viewing).map((preference) => {
            return preferenceSetupSimple(preference);
          })}
        </div>
      )}
    </div>
  );
};

export default LeaderPrefs;
