import React, { useState, useEffect, useRef } from "react";
import QuizHolder from "./QuizHolder";
import { logClick } from "../../utils/clicktracker";
import {
  landing,
  campaignImg,
  page_base,
} from "../../utils/quiz_page_rotation";
import { setPreloadedImage } from "../../utils/helpful";

const QuizLandingHero = ({ version, campaign, start }) => {
  const [page_content, setPage] = useState(page_base);
  const [campaign_img, setCampaign] = useState("");
  const [quiz_active, setActive] = useState(false);

  useEffect(() => {
    setPage(landing(version));
    setCampaign(campaignImg(campaign));
    if (start) {
      startQuiz();
    }
  }, []);

  const startQuiz = async () => {
    setActive(true);
    await logClick(version, campaign, "start");
    setPreloadedImage(
      "https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbdevices.gif"
    );
  };
  const bolding = (entry, type) => {
    try {
      const compiled = [];
      entry.split("[[").forEach((segment, index) => {
        if (segment.includes("]]")) {
          const parts = segment.split("]]");
          if (type === "bold") {
            compiled.push(<b key={index}>{parts[0]}</b>);
            compiled.push(parts[1]);
          }
        } else {
          compiled.push(segment);
        }
      });
      return compiled;
    } catch (e) {
      return "Uncover the online business that's perfectly tailored to you and learn how to grow it effortlessly. Take our free 2-minute quiz and get your personalized report today!";
    }
  };
  if (quiz_active) return <QuizHolder version={version} campaign={campaign} />;
  return (
    <div className="quiz-land-hero-all">
      <div className="quiz-land-hero-left">
        <div>
          <p className="hero-title">
            {page_content.bold_title[campaign].title}{" "}
            <span>{page_content.bold_title[campaign].span}</span>{" "}
            {page_content.bold_title[campaign].title2}
          </p>
          <div style={{ marginBottom: "1em" }}>
            {/* {page_content.sub_bullets.map((bullet, index) => {
              return (
                <p key={index} className="hero-list">
                  <span style={{ color: "#4c77cd", fontSize: "20px" }}>✔ </span>
                  {bullet}
                </p>
              );
            })} */}
          </div>
          <p className="quiz-land-subhead">
            <span style={{ display: "inline-block" }}>
              {bolding(page_content.campaign_bullet[campaign], "bold")}
            </span>
          </p>

          <div className="quiz-landing-btn" onClick={startQuiz}>
            {page_content.action1}
          </div>
        </div>
      </div>
      <div className="hero-right-ai">
        <div>
          <img className="hero-img" src={campaign_img} />
        </div>
      </div>
    </div>
  );
};

export default QuizLandingHero;
