import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useGetLatestMutation } from "../slices/newsletterApiSlice";
import { setLatest } from "../slices/newsletterApiSlice";
import AppHadError from "../appwide/AppHadError";
import { Link } from "react-router-dom";
import axios from "axios";
import "../assets/styling/misc.css";

const Latest = () => {
  const [loading, setLoading] = useState(false);
  const [viewing, setViewing] = useState({});
  const [err, setErr] = useState(false);

  const dispatch = useDispatch();

  const [getlatest, { isLoading }] = useGetLatestMutation();
  const { latest } = useSelector((state) => state.newsletter);
  useEffect(() => {
    setLoading(true);
    loadLatest();
  }, []);

  useEffect(() => {
    const tableElements = document.querySelectorAll(
      'table[data-tbb="tbb-footer"]'
    );

    // Get the last table element.
    const lastTableElement = tableElements[tableElements.length - 1];

    // Check if the lastTableElement exists before removing it.
    if (lastTableElement) {
      lastTableElement.remove();
    }
  }, [viewing]);
  const loadLatest = async () => {
    setLoading(true);
    try {
      //check redux first
      if (latest.html) {
        setViewing(latest);
        setLoading(false);
      } else {
        //go to db
        const res = await axios.get("/api/newsletter/latest");
        setViewing(res.data);
        if (res.err || res.data.err) setErr(true);
        setLoading(false);
        dispatch(setLatest(res.data));
      }
    } catch (err) {
      console.log("err");
    }
  };
  if (loading) {
    return <Loader h={"15em"} m={"0"} w={"100%"} />;
  }

  return (
    <div>
      {err ? (
        <AppHadError />
      ) : (
        <div>
          <div className="latest-header">
            <p className="title">{viewing.subject}</p>
            <p className="date">{viewing.send_date}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: viewing.html,
            }}
          />
          <hr style={{ maxWidth: "620px", margin: "auto" }} />
          <div className="latest-footer">
            <p style={{ margin: "0" }}>
              <Link to="/sublanding">
                <span>Subscribe</span>
              </Link>{" "}
              to get these in your inbox!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Latest;
