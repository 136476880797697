import React, { Component } from "react";
import AppHadError from "./AppHadError";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // send to DB (Pnotes)
    if (import.meta.env.DEV) {
      // console.log(error, errorInfo);
      console.log("system error - please contact support");
    }
  }

  render() {
    if (this.state.hasError) {
      // You can return a custom error UI here
      return <AppHadError />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
