import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "../../assets/styling/sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HandymanIcon from "@mui/icons-material/Handyman";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BallotIcon from "@mui/icons-material/Ballot";

const Sidebar = ({ switchPage }) => {
  const [section, setSection] = useState("#intro");
  const { account } = useSelector((state) => state.profile);

  const tc = [
    {
      title: "Privacy",
      link: "/privacy",
    },
    {
      title: "Terms",
      link: "/terms",
    },
    {
      title: "Latest NewsLetter", //only one that goes off account page
      link: "/latest",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "FAQ",
      link: "/faq",
    },
    {
      title: "Contact",
      link: "/contact",
    },
  ];
  const data = [
    {
      title: "Account Info",
      icon: <ManageAccountsIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#account",
    },
    {
      title: "Leader Persona",
      icon: <BallotIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#traits",
    },
    {
      title: "Update Password",
      icon: <HandymanIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#password",
    },
    {
      title: "Membership",
      icon: (
        <AutoAwesomeIcon
          className="side-icon"
          sx={{
            fontSize: 30,
            color: account.membership_active === true ? "#353535" : "red",
          }}
        />
      ),
      link: "#membership",
    },
    {
      title: "Logout",
      icon: <LogoutIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#logout",
    },
  ];
  const changePage = (item) => {
    setSection(item.link);
    switchPage(item.link);
  };
  return (
    <div className="sidebar">
      <Link className="tc-side-link" to="/">
        <div className="side-logo">
          <img
            src={"https://d2oa23hr7q6t96.cloudfront.net/base/tbbheader.jpg"}
            alt="The Boss Brief logo"
          />
        </div>
      </Link>
      <ul>
        {data.map((item, index) => (
          <li
            onClick={() => changePage(item)}
            className={item.link === section ? "selected" : ""}
            key={index}
          >
            {item.icon}
            <span className="side-item">{item.title}</span>
          </li>
        ))}
      </ul>
      <ul className="tc-links">
        {tc.map((item, index) => (
          <li
            // onClick={() => setSection(item.link)}
            className={item.link === section ? "selected" : ""}
            key={index}
          >
            <Link className="tc-side-link" to={item.link}>
              <span className="tc-side-item">{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
