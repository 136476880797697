import React from "react";
import AddIcon from "@mui/icons-material/Add";

const CCInfo = () => {
  return (
    <div className="cc-info">
      <div className="cc-section">
        <img src="/images/chairlogo.jpeg" />
      </div>
      <div className="cc-section">
        <AddIcon sx={{ fontSize: 35, color: "#6082d4" }} />
      </div>
      <div className="cc-section">
        <img src="/images/Stripe.png" />
      </div>
      <div style={{ margin: "1em 0" }}>
        We've partnered with Stripe to ensure your data is protected. No charges
        will be applied until after your free trial. Click below to enter your
        payment details and finalize your trial setup.
      </div>
    </div>
  );
};

export default CCInfo;
