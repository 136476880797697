import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styling/misc.css";

const Contact = () => {
  const [partner, setPartner] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (location.search.includes("advertise")) {
      setPartner(true);
    } else {
      setPartner(false);
    }
  }, [location]);
  const highlight = {
    borderBottom: "3px solid #6082d4",
  };
  return (
    <div className="faq">
      <h1>Contact Us</h1>

      <p style={{ fontWeight: "400", marginBottom: "10px" }} className="faq-q">
        If you want to talk to us directly please use the following email
        addresses:
      </p>
      <ul>
        <li className="faq-a">
          <span>Mr. Brief</span>
          <b>: brief@thebossbrief.com</b>
        </li>
        <li className="faq-a">
          <span style={partner ? highlight : null}>Advertising</span>
          <b style={partner ? highlight : null}>
            : partnerships@thebossbrief.com
          </b>
        </li>
        <li className="faq-a">
          <span>Support</span>
          <b>: support@thebossbrief.com</b>
        </li>
      </ul>
    </div>
  );
};

export default Contact;
