export const combo = {
  v1: {
    //Hero Section -
    hero_img: "/images/lesson_de.jpg",
    bold_title: {
      whole_title: "Find Your Perfect Business Fit!",
      title: "Find Your ",
      span: "Perfect Business",
      title2: " Fit!",
    },
    //target group
    group_title: "The Path to Your Perfect Business", //"Helping Busy Professionals Win",
    //bullets
    value_blurb:
      "Discover the online business that [[fits you best]] and learn how to grow it effortlessly.",

    action1: "Take The Quiz!",
    heroAction: "Take The Free Quiz!",
    titleSection2: "Get Your Free Business Fit Report",
    blurbSection2: "Lets go ahead and get started",
    //What You'll get section
    step1: {
      img: "",
      title: "Start with the Quiz",
      text: "Take our free, 2-minute quiz to discover the type of business that aligns with your personality and strengths. It's quick, easy, and gives you instant insights to get started on your path to success.",
    },
    step2: {
      img: "",
      title: "Evaluate Your Results",
      text: "Receive a detailed analysis of business types tailored to your profile. Identify which ones align with your strengths and passions, giving you a clear path to starting a business you'll love.",
    },
    step3: {
      img: "",
      title: "Grow It with Automation",
      text: "Select your ideal business and unlock strategies to automate key operations with AI. Move 10x faster, free up time, and focus on what matters most as you scale your business with ease.",
    },
    //Why Now Section
    why_now_title:
      "Capitalize on the AI Boom and Build Your Online Business Today",
    why_now_intro:
      "The AI revolution is here, reshaping industries and creating endless opportunities for entrepreneurs. Now is the perfect time to harness AI and build a thriving online business that sets you apart.",
  },
};

export const stat_base = [
  {
    stat: "$15.7 Trillion",
    context: "AI's projected contribution to the economy by 2030.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3d07fc14f890e7f1d1e09d2f7ce218ea9368a9f1098ef32df339b765d6340989",
  },
  {
    stat: "83%",
    context: "Small businesses already boosting efficiency using AI.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/3f458669d338c2a88848523ce520a78fcd2c0630f6f125dec8ab88875c119ff9",
  },
  {
    stat: "$8 Trillion",
    context: "Predicted global eCommerce market size by 2027.",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/4b11201f06b73387adb1b8f6ca801dbc2b95f7ea9c6cb0d0fb9f15860b8ecf82",
  },
  {
    stat: "77%",
    context:
      "Consumers willing to pay more for better service - AI can make it happen",
    img: "https://d2oa23hr7q6t96.cloudfront.net/66ccc34991c174e884be5df8/Id/6c9e39247fea7d32fa1167e9b66c4661c34e7ac0b378a26a8fdb480e0c336cc3",
  },
];

// Social media commerce is expected to reach $8.5 trillion by 2030
//Creator economy valued at $104.2B, expected to double by 2027.
//77% of consumers say they're willing to pay more for a small business with a better customer service experience
//65% of global businesses have adopted AI to reduce manual or repetitive tasks
//91% of small businesses using AI say it will help their business grow in the future
