import React, { useState, useRef } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import "../assets/styling/hero.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useSignupMutation } from "../slices/usersApiSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import ReCAPTCHA from "react-google-recaptcha";

const Hero = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [signup, { isLoading }] = useSignupMutation();
  const recaptchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") return;

    try {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      const res = await signup({ email, captchaToken }).unwrap();
      fbq("track", "Subscribe");
      /*Linkedin */ //window.lintrk("track", { conversion_id: 14415290 });
      //sparkloop to handle redirect
      //navigate("/subbed");
    } catch (error) {
      console.log("error");
      setErr(true);
    }
  };
  const err_msg = (
    <span className="err-msg">Error... please try again later</span>
  );
  return (
    <div style={{ marginTop: "1em" }}>
      <div className="hero-left">
        <div>
          <p className="hero-title">
            Leadership news that's <span>smart</span> & <span>fun</span>!
          </p>
          <p className="subhead">
            Get the latest in leadership news and trends with a
            <strong> free newsletter every Monday! </strong>Designed for
            time-strapped managers, we keep things informative, enjoyable and
            brief. 😉
          </p>
          {isLoading ? (
            <Loader h={"12em"} m={"0"} w={"300px"} />
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                {err ? err_msg : null}
                <TextField
                  className="hero-input"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                  id="filled-basic"
                  label="Your Email Address"
                />
                <Button className="hero-sub" type="submit">
                  Subscribe
                </Button>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={"6LcUkYkoAAAAADH7KJoPEM1bYaEqkipI8wBlPCLU"}
                  size="invisible"
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="hero-right">
        <div>
          <img className="hero-img" src="/images/FLHCircle.jpeg" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
