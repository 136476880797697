import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useGetLatestMutation } from "../slices/newsletterApiSlice";
import { setArchive } from "../slices/newsletterApiSlice";
import AppHadError from "../appwide/AppHadError";
import { Link } from "react-router-dom";
import axios from "axios";
import "../assets/styling/archive.css";

const Archive = () => {
  const [loading, setLoading] = useState(false);
  const [viewing, setViewing] = useState([]);
  const [err, setErr] = useState(false);

  const dispatch = useDispatch();

  const { archive_data } = useSelector((state) => state.newsletter);
  useEffect(() => {
    setLoading(true);
    loadRecent();
  }, []);
  const loadRecent = async () => {
    setLoading(true);
    try {
      //check redux first
      if (archive_data.length > 0) {
        setViewing(archive_data);
        setLoading(false);
      } else {
        //go to db
        const res = await axios.get("/api/newsletter/archive-list");
        if (res.err || res.data.err) {
          setErr(true);
        } else {
          setViewing(res.data.archive);
          dispatch(setArchive(res.data.archive));
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("err");
    }
  };
  if (loading) {
    return <Loader h={"15em"} m={"0"} w={"100%"} />;
  }

  return (
    <div>
      {err ? (
        <AppHadError />
      ) : (
        <div className="feed-archive">
          <p className="archive-list">Newsletter Archive</p>
          {viewing.map((item, index) => (
            <Link
              className="archive-item"
              key={index}
              to={"/view?nl=" + item.id}
            >
              <div className="feed-box">
                <img src={item.img} className="archive-image" />
                <div className="archive-info">
                  <p className="archive-date">{item.date}</p>
                  <p className="feed-title">{item.title}</p>

                  <p className="feed-preview">{item.preview}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Archive;
