import React from "react";
import "../assets/styling/misc.css";
import { Container } from "react-bootstrap";

const PrivacyScreen = () => {
  return (
    <Container>
      <div className="terms privacy">
        <h1>
          <b>Privacy Policy</b>
        </h1>
        <hr style={{ marginTop: "0" }} />
        <p>Last updated June 25, 2024</p>
        <p>
          This privacy notice for The Boss Brief LLC ("<b>we</b>," "<b>us</b>,"
          or "<b>our</b>"), describes how and why we might collect, store, use,
          and/or share ("<b>process</b>") your information when you use our
          services ("<b>Services</b>"), such as when you:
        </p>
        <ul>
          <li>
            Visit our website at{" "}
            <a href="https://www.thebossbrief.com">
              http://www.thebossbrief.com
            </a>
            , or any website of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including our newsletter,
            sales, marketing, or events
          </li>
        </ul>
        <p>
          Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any
          questions or concerns, please contact us at{" "}
          <b>privacy@thebossbrief.com</b>.
        </p>

        <h2 id="cookies">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3>Personal information you disclose to us</h3>
        <p>
          <strong>In Short</strong>: We collect personal information that you
          provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, participate in our quizzes, express
          an interest in obtaining information about us or our products and
          Services, when you participate in activities on the Services, or
          otherwise when you contact us.
        </p>
        <p>
          <b>Personal Information Provided by You.</b> The personal information
          that we collect depends on the context of your interactions with us
          and the Services, the choices you make, and the products and features
          you use. The personal information we collect may include the
          following:
          <li>email address</li>
          <li>job titles</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>quiz responses</li>
        </p>
        <p>
          <b>Sensitive Information.</b> We do not process sensitive information.
        </p>

        <p>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you choose to make purchases, such as your
          payment instrument number, and the security code associated with your
          payment instrument. All payment data is handled and stored by Stripe.
          You may find their privacy notice link(s) here:{" "}
          <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h3>Information automatically collected</h3>
        <p>
          <strong>In Short</strong>: Some information — such as your Internet
          Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>

        <p>The information we collect includes:</p>
        <ul>
          <li>
            Cookies. Cookies are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit{" "}
            <a href="http://www.allaboutcookies.org">
              http://www.allaboutcookies.org
            </a>
          </li>
          <li>
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage, and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type, and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches, and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called "crash dumps"), and
            hardware settings).
          </li>
          <li>
            Device Data. We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>
        </ul>

        <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
        <p>
          <strong>In Short</strong>: We process your information to provide,
          improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </strong>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <strong>
              To deliver and facilitate delivery of services to the user.
            </strong>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <strong>
              To respond to user inquiries/offer support to users.
            </strong>{" "}
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <strong>To send administrative information to you.</strong> We may
            process your information to send you details about our products and
            services, changes to our terms and policies, and other similar
            information.
          </li>
          <li>
            <strong>To fulfill and manage your orders.</strong> We may process
            your information to fulfill and manage your orders, payments,
            returns, and exchanges made through the Services.
          </li>
          <li>
            <strong>To enable user-to-user communications.</strong> We may
            process your information if you choose to use any of our offerings
            that allow for communication with another user.
          </li>
          <li>
            <strong>To request feedback.</strong> We may process your
            information when necessary to request feedback and to contact you
            about your use of our Services.
          </li>
          <li>
            <strong>
              To send you marketing and promotional communications.
            </strong>
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.
          </li>
          <li>
            <strong>To protect our Services.</strong> We may process your
            information as part of our efforts to keep our Services safe and
            secure, including fraud monitoring and prevention.
          </li>
          <li>
            <strong>To identify usage trends.</strong> We may process
            information about how you use our Services to better understand how
            they are being used so we can improve them.
          </li>
          <li>
            <strong>To save or protect an individual's vital interest.</strong>{" "}
            We may process your information when necessary to save or protect an
            individual's vital interest, such as to prevent harm.
          </li>
          <li>
            <strong>
              To generate personalized reports and recommendations.
            </strong>{" "}
            We process your quiz responses to analyze your preferences and
            provide you with tailored business opportunities that match your
            criteria.
          </li>
          <li>
            <strong> To improve our quizzes and Services.</strong> We may
            analyze your quiz responses to enhance the content and functionality
            of our quizzes and reports, ensuring a better user experience.
          </li>
        </ul>

        <h2> 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
        <p>
          <strong>In Short:</strong> We only process your personal information
          when we believe it is necessary and we have a valid legal reason
          (i.e., legal basis) to do so under applicable law, like with your
          consent, to comply with laws, to provide you with services to enter
          into or fulfill our contractual obligations, to protect your rights,
          or to fulfill our legitimate business interests.
        </p>
        <p>
          <b>
            <em>
              If you are located in the EU or UK, this section applies to you.
            </em>
          </b>
        </p>

        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>

        <ul>
          <li>
            <strong>Consent</strong>. We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. By participating in our quizzes
            and providing your responses, you consent to the processing of your
            personal information for the purposes described in our Privacy
            Policy and Terms of Service. You can withdraw your consent at any
            time. Learn more about{" "}
            <a href="#withdraw-consent">withdrawing your consent.</a>
          </li>

          <li>
            <strong>Performance of a Contract.</strong> We may process your
            personal information when we believe it is necessary to fulfill our
            contractual obligations to you, including providing our Services or
            at your request prior to entering into a contract with you.
          </li>

          <li>
            <strong> Legitimate Interests.</strong> We may process your
            information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not
            outweigh your interests and fundamental rights and freedoms. For
            example, we may process your personal information for some of the
            purposes described in order to:
            <ul>
              <li>
                Send users information about special offers and discounts on our
                products and services
              </li>
              <li>
                Analyze how our Services are used so we can improve them to
                engage and retain users
              </li>
              <li>Diagnose problems and/or prevent fraudulent activities</li>
              <li>
                Understand how our users use our products and services so we can
                improve user experience
              </li>
            </ul>
          </li>

          <li>
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>

          <li>
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>

        <p>
          <b>
            <em>If you are located in Canada, this section applies to you.</em>
          </b>
        </p>

        <p>
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time.
        </p>

        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>

        <ul>
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>

          <li>For investigations and fraud detection and prevention</li>

          <li>For business transactions provided certain conditions are met</li>

          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>

          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>

          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </li>

          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>

          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>

          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>

          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>

          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
        <h2> 4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
        <p>
          <strong>In Short</strong>: We may share information in specific
          situations described in this section and/or with the following
          categories of third parties.
        </p>
        <p>
          <strong>
            Vendors, Consultants, and Other Third-Party Service Providers
          </strong>
          . We may share your data with third-party vendors, service providers,
          contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that
          work. We have contracts in place with our third parties, which are
          designed to help safeguard your personal information. This means that
          they cannot do anything with your personal information unless we have
          instructed them to do it. They will also not share your personal
          information with any organization apart from us. They also commit to
          protect the data they hold on our behalf and to retain it for the
          period we instruct. The categories of third parties we may share
          personal information with are as follows:
        </p>
        <ul>
          <li>Ad Networks</li>
          <li>Affiliate Marketing Programs</li>
          <li>Cloud Computing Services</li>
          <li>Communication & Collaboration Tools</li>
          <li>Data Analytics Services</li>
          <li>Data Storage Service Providers</li>
          <li>Order Fulfillment Service Providers</li>
          <li>Payment Processors</li>
          <li>Performance Monitoring Tools</li>
          <li>Product Engineering & Design Tools</li>
          <li>Sales & Marketing Tools</li>
          <li>Website Hosting Service Providers</li>
          <li>User Account Registration & Authentication Services</li>
        </ul>

        <p>
          We also may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <strong>Business Transfers</strong>. We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li>
            <strong>Affiliates</strong>. We may share your information with our
            affiliates, in which case we will require those affiliates to honor
            this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we
            control or that are under common control with us.
          </li>
          <li>
            <strong>Business Partners</strong>. We may share your information
            with our business partners to offer you certain products, services,
            or promotions.
          </li>
          <li>
            <strong>Other Users</strong>. When you share personal information
            (for example, by posting comments, contributions, or other content
            to the Services) or otherwise interact with public areas of the
            Services, such personal information may be viewed by all users and
            may be publicly made available outside the Services in perpetuity.
            Similarly, other users will be able to view descriptions of your
            activity, communicate with you within our Services, and view your
            profile.
          </li>
        </ul>

        <h3>Opting out</h3>
        <ul>
          <li>
            You can opt-out of Google Analytics here:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </li>

          <li>
            You can opt out of Google targeted advertising here:{" "}
            <a href="https://www.google.com/settings/ads/anonymous">
              https://www.google.com/settings/ads/anonymous
            </a>
          </li>
          <li>
            You can opt out of Facebook targeted advertising here:{" "}
            <a href="https://www.facebook.com/settings/?tab=ads">
              https://www.facebook.com/settings/?tab=ads
            </a>
          </li>
          <li>
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance's opt-out portal at:{" "}
            <a href="http://optout.aboutads.info/">
              http://optout.aboutads.info/
            </a>
          </li>
        </ul>
        <h2> 5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
        <p>
          <strong>In Short:</strong> We are not responsible for the safety of
          any information that you share with third parties that we may link to
          or who advertise on our Services, but are not affiliated with, our
          Services.
        </p>
        <p>
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </p>

        <h2> 6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p>
          <strong>In Short:</strong> We may use cookies and other tracking
          technologies to collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </p>
        <p>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p>
          To the extent these online tracking technologies are deemed to be a
          "sale"/"sharing" (which includes targeted advertising, as defined
          under the applicable laws) under applicable US state laws, you can opt
          out of these online tracking technologies by submitting a request as
          described below under section "DO UNITED STATES RESIDENTS HAVE
          SPECIFIC PRIVACY RIGHTS?"
        </p>
        <p>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>

        <h2> 7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>
          <strong>In Short</strong>: We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          three (3) months past the termination of the user's account.
        </p>

        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>

        <h2> 8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p>
          <strong>In Short:</strong> We aim to protect your personal information
          through a system of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>

        <h2> 9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        <p>
          <strong>In Short:</strong> We do not knowingly collect data from or
          market to children under 18 years of age.
        </p>
        <p>
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at <strong>privacy@thebossbrief.com</strong>.
        </p>

        <h2> 10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>
          <strong>In Short:</strong> Depending on your state of residence in the
          US or in some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), Switzerland, and Canada, you have rights that
          allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time, depending on your country, province, or state of residence.
        </p>
        <p>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your
          personal information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision- making. In certain circumstances, you may also
          have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section "HOW CAN YOU CONTACT US ABOUT
          THIS NOTICE?" below.
        </p>

        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>

        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your <a>Member State data protection authority</a> or{" "}
          <a>UK data protection authority.</a>
        </p>

        <p>
          If you are located in Switzerland, you may contact the{" "}
          <a>Federal Data Protection and Information Commissioner.</a>
        </p>

        <p id="withdraw-consent">
          <b>Withdrawing your consent</b>: If we are relying on your consent to
          process your personal information, which may be express and/or implied
          consent depending on the applicable law, you have the right to
          withdraw your consent at any time. You can withdraw your consent at
          any time by contacting us by using the contact details provided in the
          section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </p>

        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>

        <p>
          <strong>
            Opting out of marketing and promotional communications
          </strong>
          : You can unsubscribe from our marketing and promotional
          communications at any time by clicking on the unsubscribe link in the
          emails that we send, or by contacting us using the details provided in
          the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You
          will then be removed from the marketing lists. However, we may still
          communicate with you — for example, to send you service-related
          messages that are necessary for the administration and use of your
          account, to respond to service requests, or for other non-marketing
          purposes.
        </p>
        <h3>Account Information</h3>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can contact us using the
          contact information provided.
        </p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <b>Cookies and similar technologies:</b> Most Web browsers are set to
          accept cookies by default. If you prefer, you can usually choose to
          set your browser to remove cookies and to reject cookies. If you
          choose to remove cookies or reject cookies, this could affect certain
          features or services of our Services. You may also{" "}
          <a href="http://www.aboutads.info/choices/">
            opt out of interest-based advertising by advertisers
          </a>{" "}
          on our Services.
        </p>

        <p>
          If you have questions or comments about your privacy rights, you may
          email us at <b>privacy@thebossbrief.com</b>.
        </p>

        <h2> 11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <p>
          California law requires us to let you know how we respond to web
          browser DNT signals. Because there currently is not an industry or
          legal standard for recognizing or honoring DNT signals, we do not
          respond to them at this time.
        </p>

        <h2> 12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

        <p>
          <strong>In Short</strong>: If you are a resident of California,
          Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
          Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or
          Virginia, you may have the right to request access to and receive
          details about the personal information we maintain about you and how
          we have processed it, correct inaccuracies, get a copy of, or delete
          your personal information. You may also have the right to withdraw
          your consent to our processing of your personal information. These
          rights may be limited in some circumstances by applicable law. More
          information is provided below.
        </p>

        <h3>Categories of Personal Information We Collect</h3>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>

        <ul>
          <li>A. Identifiers: YES</li>
          <li>
            B. Personal information categories listed in the California Customer
            Records statute: NO
          </li>
          <li>
            C. Protected classification characteristics under California or
            federal law: YES
          </li>
          <li>D. Commercial information: NO</li>
          <li>E. Biometric information: NO</li>
          <li>F. Internet or other similar network activity: NO</li>
          <li>G. Geolocation data: NO</li>
          <li>
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information: NO
          </li>
          <li>I. Professional or employment-related information: NO</li>
          <li>J. Education Information: NO</li>
          <li>K. Inferences drawn from other personal information: NO</li>
          <li>L. Sensitive Personal Information: NO</li>
        </ul>

        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>

        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li>Category A - As long as the user has an account with us</li>
          <li>Category C - As long as the user has an account with us</li>
        </ul>
        <h3>Sources of Personal Information</h3>

        <p>
          Learn more about the sources of personal information we collect in
          "WHAT INFORMATION DO WE COLLECT?"
        </p>

        <h3>How We Use and Share Personal Information</h3>
        <p>
          Learn about how we use your personal information in the section, "HOW
          DO WE PROCESS YOUR INFORMATION?"
        </p>

        <p>We collect and share your personal information through:</p>
        <ul>
          <li>Beacons/Pixels/Tags</li>
        </ul>
        <p>
          <strong>Will your information be shared with anyone else?</strong>
        </p>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        </p>

        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
        </p>

        <p>
          We have not sold or shared any personal information to third parties
          for a business or commercial purpose in the preceding twelve (12)
          months. We have disclosed the following categories of personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months:
        </p>

        <p>
          The categories of third parties to whom we disclosed personal
          information for a business or commercial purpose can be found under
          "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        </p>

        <h3>Your Rights</h3>
        <p>
          You have rights under certain US state data protection laws. However,
          these rights are not absolute, and in certain cases, we may decline
          your request as permitted by law. These rights include:
        </p>

        <ul>
          <li>
            <strong>Right to know</strong> whether or not we are processing your
            personal data
          </li>
          <li>
            <strong>Right to access</strong> your personal data
          </li>
          <li>
            <strong>Right to correct</strong> inaccuracies in your personal data
          </li>
          <li>
            <strong>Right to request</strong> the deletion of your personal data
          </li>
          <li>
            <strong>Right to obtain a copy</strong> of the personal data you
            previously shared with us
          </li>
          <li>
            <strong>Right to non-discrimination</strong> for exercising your
            rights
          </li>
          <li>
            <strong>Right to opt out</strong> of the processing of your personal
            data if it is used for targeted advertising (or sharing as defined
            under California's privacy law), the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </li>
        </ul>
        <p>
          Depending upon the state where you live, you may also have the
          following rights:
        </p>
        <ul>
          <li></li>
          <li>
            Right to obtain a list of the categories of third parties to which
            we have disclosed personal data (as permitted by applicable law,
            including California's and Delaware's privacy law)
          </li>
          <li>
            Right to obtain a list of specific third parties to which we have
            disclosed personal data (as permitted by applicable law, including
            Oregon's privacy law)
          </li>
          <li>
            Right to limit use and disclosure of sensitive personal data (as
            permitted by applicable law, including California's privacy law)
          </li>
          <li>
            Right to opt out of the collection of sensitive data and personal
            data collected through the operation of a voice or facial
            recognition feature (as permitted by applicable law, including
            Florida's privacy law)
          </li>
        </ul>

        <h3>How to Exercise Your Rights</h3>
        <p>
          To exercise these rights, you can contact us by submitting a data
          subject access request, by emailing us at{" "}
          <strong>privacy@thebossbrief.com</strong>, or by referring to the
          contact details at the bottom of this document.
        </p>
        <p>
          Under certain US state data protection laws, you can designate an
          authorized agent to make a request on your behalf. We may deny a
          request from an authorized agent that does not submit proof that they
          have been validly authorized to act on your behalf in accordance with
          applicable laws.
        </p>
        <h3>Request Verification</h3>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. We will only use personal information provided in your
          request to verify your identity or authority to make the request.
          However, if we cannot verify your identity from the information
          already maintained by us, we may request that you provide additional
          information for the purposes of verifying your identity and for
          security or fraud-prevention purposes.
        </p>
        <p>
          If you submit the request through an authorized agent, we may need to
          collect additional information to verify your identity before
          processing your request and the agent will need to provide a written
          and signed permission from you to submit such request on your behalf.
        </p>

        <h3>Appeals</h3>
        <p>
          Under certain US state data protection laws, if we decline to take
          action regarding your request, you may appeal our decision by emailing
          us at privacy@thebossbrief.com. We will inform you in writing of any
          action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions. If your appeal
          is denied, you may submit a complaint to your state attorney general.
        </p>

        <h3>California "Shine The Light" Law</h3>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        </p>

        <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p>
          <strong>In Short:</strong> Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </p>

        <h2> 14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h2>

        <div className="end-p">
          <p style={{ marginBottom: "1em" }}>
            If you have questions or comments about this notice, you may email
            us at <b>privacy@thebossbrief.com</b> or contact us by post at:
          </p>
          <p>The Boss Brief LLC</p>
          <p> 5900 Balcones Drive STE 100</p>
          <p> Austin, TX 78731</p>
          <p> United States</p>
        </div>
        <h2>
          {" "}
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>

        <p>
          Based on the applicable laws of your country or state of residence in
          the US, you may have the right to request access to the personal
          information we collect from you, details about how we have processed
          it, correct inaccuracies, or delete your personal information. You may
          also have the right to withdraw your consent to our processing of your
          personal information. These rights may be limited in some
          circumstances by applicable law. To request to review, update, or
          delete your personal information, please contact us at{" "}
          <b>privacy@thebossbrief.com</b>
        </p>
      </div>
    </Container>
  );
};

export default PrivacyScreen;
