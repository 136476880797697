import React from "react";

const QuoteSection = ({ version }) => {
  const quote_block = {
    v2: {
      quote:
        "AI is not just about efficiency gains; it's about opening up new possibilities, unlocking human potential.",
    },
    l: {
      quote:
        "The single biggest way to impact an organization is to focus on leadership development.",
    },
    ai: {
      quote:
        "AI won't replace humans - but humans with AI will replace humans without AI",
    },
    v1: {
      quote:
        "AI has the power to unlock unprecedented productivity and innovation across all industries.",
    },
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        className="separator"
        style={{
          display: "inline-block",
          width: "auto",
          position: "relative",
          border: "none",
          margin: "2em auto",
          color: "white",
          padding: "1em 2em",
        }}
      >
        <span className="q-ai q-left">❝</span>{" "}
        <span style={{ fontSize: "1.4em", fontWeight: "600" }}>
          {quote_block[version].quote}
        </span>{" "}
        <span className="q-ai q-right">❞</span>
        <div className="quote-author"></div>
      </div>
    </div>
  );
};

export default QuoteSection;

//businesses that deploy AI to help them innovate will be the winners in the next era of business.
