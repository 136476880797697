import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import loader from "../../assets/lottie/pen.json";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogActions from "@mui/material/DialogActions";
import TimerIcon from "@mui/icons-material/Timer";
import CountdownTimer from "../../components/Countdown";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../assets/styling/poll.css";

const Poll = () => {
  //check token - if not in url then redirect home or say you must be a subscriber to vote. Check the newseltter for the link (people who sub might come by accident without token. So redirect to email for now. Can add email search later)

  // States for handling UI and data
  const [pollData, setPollData] = useState({
    question: "",
    options: [],
    reason: "",
  });
  const [userToken, setUtk] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [option_key, setSelectedKey] = useState(-1);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [voteRecordedDialogOpen, setVoteRecordedDialogOpen] = useState(false);
  const [voteError, setVoteErrorDialogOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "Something went wrong ☹️ Please check back later. Or you can contact us at support@thebossbrief.com"
  );
  const no_utk =
    "Hey there 👋, You need to be a subscriber of The Boss Brief newsletter to vote in our polls. If you are a subscriber perhaps there's an issue with your link. Try clicking the poll options in the newsletter we sent.";
  const [voted, setVoted] = useState(false);
  const navigate = useNavigate();
  // Fetch poll data from backend - this would be your actual fetch logic
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    loadPoll(params.get("utk"), params.get("selection"), params.get("nl_ref"));
  }, []);

  const loadPoll = async (utk, selection, news_ref) => {
    try {
      if (utk) {
        setUtk(utk);
        const response = await axios.post("/api/poll/verify", {
          utk,
          news_ref,
        });
        if (response && response.data) {
          setPollData(response.data);
          setIsSubmitDisabled(false);
          //check if poll closed
          if (response.data.error === true) {
            setVoteErrorDialogOpen(true);
            setErrorMsg(response.data.error_msg);
            return;
          }

          //check selection
          if (selection) {
            const pre_select = parseInt(selection);
            setSelectedOption(response.data.options[pre_select]);
            setSelectedKey(pre_select);
          }
        } else {
          setVoteErrorDialogOpen(true);
          setIsSubmitDisabled(true);
        }
      } else {
        setVoteErrorDialogOpen(true);
        setErrorMsg(no_utk);
      }
    } catch (error) {
      //   alert("Invalid user token. You cannot vote.");
      setVoteErrorDialogOpen(true);
      setIsSubmitDisabled(true);
    }
  };

  const handleOptionSelect = (option, key) => {
    setSelectedOption(option);
    setSelectedKey(key);
  };
  const haveVoted = () => {
    setVoteRecordedDialogOpen(false);
    setVoted(true);
    // navigate("/latest");
  };
  const handleSubmitVote = () => {
    const voteData = {
      poll_id: pollData.id,
      user_token: userToken,
      selection: option_key,
    };

    axios
      .post("/api/poll/vote", voteData)
      .then((response) => {
        console.log(response.data);
        setVoteRecordedDialogOpen(true);
      })
      .catch((error) => console.error("Error submitting vote:", error));
  };

  // Added styling for the selected option
  const selectedOptionStyle = (key, selected) => {
    if (selectedOption !== selected) return "lightgrey";
    const color = {
      0: "#B6358A",
      1: "#00A7ED",
      2: "#00E0BC",
      3: "#6082D4",
      4: "#D4623E",
    };
    return color[key];
  };
  return (
    <div className="about-section">
      <img
        className="poll-pic"
        src="https://d2oa23hr7q6t96.cloudfront.net/65dcf532c1d12e42a22f35f9/P/69cdcd60aac4c9476873c808b3ce1806247eaae691766c9f4225fe023e2d6706"
      />
      {!voted ? (
        <div className="poll-section">
          <div id="poll-header" className="latest-header">
            <p style={{ width: "100%" }} className="title">
              {pollData.question}
            </p>
            <div>
              {pollData.options.length > 0 ? (
                <CountdownTimer targetDate={pollData.close_date} />
              ) : null}
            </div>
          </div>
          <p className="poll-reason">{pollData.reason}</p>
          <List>
            {pollData.options.map((option, key) => (
              <ListItemButton
                key={option}
                onClick={() => handleOptionSelect(option, key)}
                disabled={isSubmitDisabled}
                sx={{
                  border: "1px solid",
                  borderColor: selectedOptionStyle(key, option),
                  borderWidth: selectedOption === option ? 3 : 1, // Adjust border width based on selection
                }}
                className="poll-option"
              >
                <ListItemText
                  sx={{
                    borderLeft: "20px solid",
                    borderColor: selectedOptionStyle(key, option),
                    padding: "0 10px",
                  }}
                  primary={option}
                />
              </ListItemButton>
            ))}
          </List>
          {pollData.options.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitVote}
              disabled={!selectedOption || isSubmitDisabled}
            >
              Submit Vote
            </Button>
          ) : (
            <div className="poll-latest">
              Check out the <Link to="/latest">latest newsletter</Link>
            </div>
          )}

          <Dialog open={voteRecordedDialogOpen} onClose={() => haveVoted()}>
            <DialogTitle>🥳 Your vote has been recorded!</DialogTitle>
            <DialogActions>
              <Button onClick={() => haveVoted()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={voteError}
            onClose={() => setVoteErrorDialogOpen(false)}
          >
            <DialogTitle>{errorMsg}</DialogTitle>
          </Dialog>
        </div>
      ) : (
        <div>
          <div className="feedback-top">
            <Lottie
              animationData={loader}
              loop={true}
              style={{
                height: "150px",
                margin: "1em 0 10px",
                display: "block",
              }}
            />
            <div className="feedback-thanks">
              <p
                style={{
                  fontFamily: "Anton",
                  fontSize: "1.5em",
                  lineHeight: "1.2",
                }}
              >
                Got a poll request? Write to us at team@thebossbrief.com
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Poll;
