import React, { useState, useEffect, forwardRef } from "react";

import "../../assets/styling/hero.css";

import { useWaitlistMutation } from "../../slices/usersApiSlice";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getIdentified } from "../../utils/ctaMap";
import {
  landing,
  campaignImg,
  page_base,
  replacePricesInString,
  price,
  old_price,
  calculateDiscount,
  checkPriceFormat,
  preorder_date,
} from "../../utils/quiz_page_rotation";
import { logClick } from "../../utils/clicktracker";
import axios from "axios";
import Loader from "../Loader";

const QuizCTA = forwardRef(
  ({ version, campaign, num_map, custom_cta, token }, ref) => {
    const [err, setErr] = useState(false);
    const [err_statement, setStatement] = useState(
      "Error... please try again later"
    );
    const [waitlist, { isLoading }] = useWaitlistMutation();
    const [page_content, setPage] = useState(page_base);
    const [showModal, setShowModal] = useState(false);
    const [buy_loading, setBuyLoading] = useState(false);

    const automate_cta_matches = ["auto"];
    const strength_cta_matches = ["str"];

    useEffect(() => {
      setPage(landing(version));
    }, []);

    const clickBuy = async () => {
      // window.open("");

      try {
        setBuyLoading(true);
        await logClick(version, campaign, "buy");
        const res = await axios.post("/api/users/temp-save", {
          tempUserId: token,
          quizData: num_map,
        });
        fbq("track", "Subscribe");
        //test link
        // window.location.href =
        //   "https://buy.stripe.com/test_3cs8x29D3eqYc8MfZ0?client_reference_id=" +
        //   token;

        // Redirect to Stripe Checkout
        //redirect

        window.location.href =
          "https://buy.stripe.com/28o046gqNexma9W6op?client_reference_id=" +
          token;
      } catch (err) {
        // console.log(err);
        console.log("Error initiating payment:");
        alert(
          "An error occurred while trying to send you to stripe. Please contact support if this happens again."
        );
      }
      //if choose to open new tab
      // window.open("https:www.apple.com");
      // // Manually set window.opener to null to apply "noopener"
      // if (window.opener) {
      //   window.opener = null;
      // }
    };
    const bolding = (entry, type) => {
      const compiled = [];
      entry.split("[[").forEach((segment, index) => {
        if (segment.includes("]]")) {
          const parts = segment.split("]]");
          if (type === "bold") {
            compiled.push(<b key={index}>{parts[0]}</b>);
            compiled.push(parts[1]);
          }
        } else {
          compiled.push(segment);
        }
      });
      return compiled;
    };

    const err_msg = <span className="err-msg">{err_statement}</span>;
    return (
      <div className="get-report" ref={ref}>
        <div
          className="report-img"
          style={{
            backgroundImage:
              "url('https://d2oa23hr7q6t96.cloudfront.net/670390dc704f15764ec889e7/D/b450168b620dfd94ce1bb7ff83062982cd2297fdd3b720063a9bea02b6737822')",
          }}
        />
        <div id="get-mine" className="signup-dos">
          <div>
            <p className="get-report-title">{page_content.titleSection2}</p>
            <p className="get-report-sub">
              Find the business that fits you best and grow it seamlessly.
            </p>
            <p className="report-preorder">
              <strong>
                Preorder and get {calculateDiscount(old_price, price)}% off
              </strong>
            </p>
            <div
              style={{
                marginBottom: "2em",
              }}
            >
              <div className="report-offer">
                <span className="offer-icon">$</span>
                <span className="current-offer">{checkPriceFormat(price)}</span>
              </div>
              <div className="report-offer">
                <span className="offer-icon">$</span>
                <span className="original-offer">
                  {checkPriceFormat(old_price)}
                </span>
              </div>
            </div>

            {/* Success Section */}
            <div className="report-f">
              <div className="report-f-check">✔ </div>
              <div className="report-f-desc">
                <p className="report-f-title">Find Your Business Match</p>
                <p>
                  Get a full analysis of 20+ online business types, each
                  examined against your profile across 12 key dimensions.
                  Identify which businesses truly align with your strengths and
                  passions for a clear path to success.
                </p>
              </div>
            </div>
            <div className="report-f">
              <div className="report-f-check">✔ </div>
              <div className="report-f-desc">
                <p className="report-f-title">
                  Supercharge Your Growth with AI
                </p>
                <p>
                  Discover how to automate 15 key online business operations
                  with AI, helping you move 10x faster and scale effortlessly.
                  Free up your time and energy to focus on what matters most as
                  your business grows with ease.
                </p>
              </div>
            </div>

            <div className="report-f">
              <div className="report-f-check">✔ </div>
              <div className="report-f-desc">
                <p className="report-f-title">
                  Transform Weaknesses Into Powerful Assets
                </p>
                <p>
                  Get access to learning modules that teach you how to harness
                  your strengths and use automation to cover your weaknesses. If
                  {" " + getIdentified(num_map) + " "}is holding you back, we'll
                  provide actionable steps to ensure it no longer limits your
                  business success.
                </p>
              </div>
            </div>

            <div className="report-f">
              <div
                style={{
                  marginTop: "10px",
                  background: "#2cc490",
                  paddingTop: "1px",
                }}
                className="report-f-check"
              >
                <CurrencyExchangeIcon sx={{ fontSize: "23px" }} />{" "}
              </div>
              <div className="report-f-desc">
                <p style={{ margin: "10px 0" }} className="report-f-title">
                  <span className="money-back">
                    Risk-Free, 100% Money-Back Guarantee
                  </span>
                </p>
                <p>
                  If you're unhappy with the product, just email us at{" "}
                  <a
                    style={{ color: "#4c77cd" }}
                    href="mailto:support@thebossbrief.com"
                  >
                    support@thebossbrief.com
                  </a>{" "}
                  within 30 days, and we'll refund your money -{" "}
                  <strong>no questions asked.</strong>
                </p>
              </div>
            </div>
            <div className="report-bottom-v">
              <p
                style={{
                  fontSize: "1.5em",
                  lineHeight: "1",
                  letterSpacing: "-1.5px",
                }}
                className="get-report-title"
              >
                {page_content.titleSection2}
              </p>

              <p style={{ textAlign: "left" }} className="report-preorder">
                <strong>Preorder details:</strong> Our team is perfecting our
                data to ensure you get the most up-to-date insights and
                strategies. As a thank-you for purchasing early, you'll receive
                {" " + calculateDiscount(old_price, price) + "% "} off!
              </p>
            </div>
            <div className="report-totals">
              <div
                style={{
                  borderBottom: "1px solid #e1e1e1",
                  padding: "10px 0 5px",
                  fontSize: "0.9rem",
                }}
              >
                {page_content.titleSection2}
              </div>
              <div style={{ padding: "5 0px 0", fontWeight: "600" }}>
                ${checkPriceFormat(price)}
              </div>
              <div style={{ padding: "0 0 10px", fontSize: "0.9rem" }}>
                {preorder_date} Delivery
              </div>
            </div>
            {buy_loading ? (
              <div className="continue-load">
                <Loader h={"3.5em"} m={"auto"} w={"100%"} />
              </div>
            ) : (
              <div className="report-continue" onClick={() => clickBuy()}>
                {page_content.offer} <ArrowForwardIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default QuizCTA;
