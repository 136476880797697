import { apiSlice } from "./apiSlice";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const NEWSLETTER_URL = "/api/newsletter";

const initialState = {
  latest: {},
  slide_data: [],
  archive_data: [],
  single: {},
};

const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    setArchive: (state, action) => {
      state.archive_data = action.payload;
    },
    setData: (state, action) => {
      state.slide_data = action.payload;
    },
    setLatest: (state, action) => {
      state.latest = action.payload;
    },
    setSingle: (state, action) => {
      state.single[action.payload.id] = action.payload.data;
    },
  },
});

export const newsletterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLatest: builder.mutation({
      query: () => ({
        url: `${NEWSLETTER_URL}/latest`,
        method: "GET",
      }),
    }),
    getSlideData: builder.mutation({
      query: () => ({
        url: `${NEWSLETTER_URL}/data`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetSlideDataMutation, useGetLatestMutation } =
  newsletterApiSlice;

export const { setData, setLatest, setSingle, setArchive } =
  newsletterSlice.actions;

export default newsletterSlice.reducer;
