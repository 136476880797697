import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    // If the event has passed, return an indicator for "Done"
    if (difference < 0) {
      return { done: true };
    }

    let timeLeft = {
      day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      sec: Math.floor((difference / 1000) % 60),
    };

    // If the time left is 24 hours or more, return null to indicate no display
    if (timeLeft.day > 0) {
      return null;
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  // Function to format the label correctly for singular or plural
  const formatLabel = (value, label) => {
    // Adjust label to add 's' only for plural forms
    const pluralLabel = label + (value === 1 ? "" : "s");
    return `${value} ${pluralLabel} `;
  };

  // Early return conditions based on timeLeft value
  if (timeLeft === null) {
    return ""; // Return empty string if over 24 hours
  }

  if (timeLeft.done) {
    // return <span className="poll-timer">Poll is Closed</span>; // Return "Done" if the event has passed
  }

  const timerComponents = Object.keys(timeLeft).reduce(
    (components, interval) => {
      if (interval === "day" || timeLeft[interval] === 0) {
        return components; // Skip day and zero values
      }
      components.push(
        <span key={interval}>{formatLabel(timeLeft[interval], interval)}</span>
      );
      return components;
    },
    []
  );

  return (
    <div className="poll-timer">
      {timerComponents.length ? (
        <div>
          <b>Poll Closes In:</b> {timerComponents}{" "}
        </div>
      ) : (
        <span>Poll is Closed</span>
      )}
    </div>
  );
};

export default CountdownTimer;
