import React from "react";
import { Collapse, Typography, Box } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const FaqItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div style={{ margin: "1em 0" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
          borderBottom: "1px solid #ddd",
          cursor: "pointer",
          backgroundColor: isOpen ? "#051c5f" : "whitesmoke",
          color: isOpen ? "#fff" : "#000",
          borderRadius: "5px",
          textAlign: "left",
        }}
        onClick={onClick}
      >
        <p style={{ margin: "0", fontWeight: "600" }}>{question}</p>
        {isOpen ? <Remove /> : <Add />}
      </Box>
      <Collapse in={isOpen}>
        <Box
          sx={{
            padding: "16px",
            border: "1px solid #ddd",
            borderTop: "none",
            backgroundColor: "#f9f9f9",
          }}
        >
          <p
            style={{
              fontSize: "1em",
              fontWeight: "400",
              textAlign: "left",
              lineHeight: "1.4",
            }}
          >
            {answer}
          </p>
        </Box>
      </Collapse>
    </div>
  );
};

export default FaqItem;
