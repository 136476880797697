import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { judgePasswordStrength } from "../utils/passCheck";
import "../assets/styling/register.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation } from "react-router-dom";
import useCheckUser from "../utils/checkUser";
import NotFound from "../appwide/NotFound";
import CompleteRegistration from "../components/CompleteRegistration";
import React from "react";
import TrialSplashScreen from "./TrialSplashScreen";

const RegisterScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pass_strength, setPassStrength] = useState("");
  const [strength_bar, setBar] = useState({ width: "", color: "" });
  const [issue, setIssue] = useState("");
  const [loggedIn, setLogin] = useState(false);
  const [splashScreen, setSplash] = useState(false);

  //checkuser stuff
  const [valid, setValid] = useState(false);
  const [resolved, setResolved] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsletter = "";
  const date = "";
  const token = searchParams.get("utk");
  const { checkUser } = useCheckUser();
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const res = await checkUser({
          token,
          newsletter,
          date,
          setValid,
          setResolved,
          section: "register",
        });
        if (res && res.email) {
          setEmail(res.email);
        }
      } catch (error) {
        console.error("Failed to fetch user email:");
      }
    };

    fetchUserEmail();
  }, [location]);
  //end checkuser stuff

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo && ["premium", "access"].includes(userInfo.type)) {
      navigate("/profile");
    } else {
    }
  }, []);

  const startRegister = () => {
    setSplash(false);
  };
  const checkPass = (pass) => {
    setPassword(pass);
    const strength = judgePasswordStrength(pass);
    setPassStrength(strength.label);
    setBar(strength.percentage);
  };
  const checkConfirmPass = (pass) => {
    setConfirmPassword(pass);
    if (password !== pass) {
      setIssue("Your passwords don't match");
    } else {
      setIssue("");
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("pass dont match");
      setIssue("Your passwords don't match");
    } else {
      try {
        const res = await register({ email, password, token }).unwrap();
        if (res && res.problem) {
          setIssue(
            "There was a problem: \n" +
              res.problem +
              (!res.nohelp
                ? "\n Please contact us at team@thebossbrief.com for help."
                : "")
          );
        } else {
          dispatch(setCredentials({ ...res }));
          setLogin(true);
        }
      } catch (err) {
        console.log("err");
        setIssue(
          "There was an error \n please contact us at team@thebossbrief.com for help."
        );
      }
    }
  };
  if (!resolved) return null;
  if (isLoading)
    return (
      <div className="reg-gradient">
        <div className="reg-loader">
          <Loader />
        </div>
      </div>
    );
  if (valid && splashScreen)
    return <TrialSplashScreen startRegister={startRegister} />;
  return (
    <>
      {valid ? (
        <div className="reg-gradient">
          {loggedIn ? (
            <CompleteRegistration />
          ) : (
            <FormContainer>
              <img
                src={
                  "https://d2oa23hr7q6t96.cloudfront.net/65c68747274ca7345019b590/I/461c74fdccd90b40a496589ae5506461c79a4311c1e9ac1d59853ab1d5d437a4"
                }
                alt="The Boss Brief logo"
                className="reg-logo"
              />
              <div className="reg-header">Create your Premium account</div>
              <p
                style={{
                  color: "grey",
                  fontSize: "0.87rem",
                  margin: "0 0 2em",
                  borderBottom: "1px solid #d6d6d6",
                  paddingBottom: "5px",
                }}
              >
                Finals steps before starting your trial
              </p>
              <Form onSubmit={submitHandler}>
                <div className="reg-email-cover">
                  <p
                    style={{
                      margin: "0",
                      fontSize: "15px",
                      display: "inline-block",
                    }}
                  >
                    This premium account will be for:
                  </p>{" "}
                  <p className="reg-email">{email}</p>
                </div>

                <Form.Group className="my-1" controlId="password">
                  <Form.Label className="reg-label">Password</Form.Label>
                  <Form.Control
                    type="password"
                    className="reg-input"
                    value={password}
                    onChange={(e) => checkPass(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {pass_strength !== "" ? (
                  <div className="pass-strength">
                    <span className="pass-label">{pass_strength}</span>
                    <div
                      style={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        style={{
                          width: strength_bar.width,
                          backgroundColor: strength_bar.color,
                        }}
                        className="pass-strength-bar"
                      ></span>
                    </div>
                  </div>
                ) : null}
                <Form.Group className="my-1" controlId="confirmPassword">
                  <Form.Label className="reg-label">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="reg-input"
                    value={confirmPassword}
                    onChange={(e) => checkConfirmPass(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
                <Button
                  disabled={
                    email === "" || password === "" || confirmPassword === ""
                      ? true
                      : false
                  }
                  type="submit"
                  variant="primary"
                  className="mt-3 reg-submit"
                >
                  Create Premium Account
                </Button>
              </Form>
              <div style={{ fontSize: "13px", textAlign: "center" }}>
                Already have an account{" "}
                <Link to={"/login"}>
                  <span>
                    Log in <ArrowForwardIcon />
                  </span>
                </Link>
              </div>
              <div
                className="reg-terms"
                style={{
                  textAlign: "center",
                  fontSize: "0.75rem",
                  margin: "2em 0 10px",
                  color: "grey",
                }}
              >
                By proceeding, you agree to the{" "}
                <Link to={"/terms"}>Terms and Conditions</Link> and{" "}
                <Link to={"/privacy"}>Privacy Policy</Link>
                {/* <p style={{ margin: "5px 0 0 " }}>
            <span>
              <Link to={"/privacy"}></Link>Privacy
            </span>
            <span>
              <Link to={"/terms"}></Link>Terms
            </span>
          </p> */}
              </div>
            </FormContainer>
          )}
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default RegisterScreen;
