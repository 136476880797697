import { useCheckTokenMutation } from "../slices/usersApiSlice"; // Adjust the path as needed

const useCheckUser = () => {
  const [check, { isLoading }] = useCheckTokenMutation();

  const checkUser = async ({
    token,
    newsletter,
    date,
    setValid,
    setResolved,
    section,
  }) => {
    try {
      const res = await check({
        token,
        newsletter,
        date,
        section,
      }).unwrap();

      if (res.valid) {
        setValid(true);
      }
      setResolved(true);
      return res;
    } catch (error) {
      console.log(error);
      console.log("error");
      setValid(false);
      setResolved(true);
      return null;
    }
  };

  return { checkUser, isLoading };
};

export default useCheckUser;
