import { apiSlice } from "./apiSlice";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const USERS_URL = "/api/users";

const initialState = {
  account: {},
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setPreferences: (state, action) => {
      state.account = action.payload;
    },
    clearProfile: (state, action) => {
      state.account = {};
    },
  },
});

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    updatePass: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/update_pass`,
        method: "POST",
        body: data,
      }),
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/subscriber`,
        method: "POST",
        body: data,
      }),
    }),
    waitlist: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/playbook-waitlist`,
        method: "POST",
        body: data,
      }),
    }),
    checkToken: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/user_access`,
        method: "POST",
        body: data,
      }),
    }),
    getPrefs: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/leader_prefs`,
        method: "POST",
        body: data,
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/me`,
        method: "PUT",
        body: data,
      }),
    }),
    postPayment: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/post-payment`,
        method: "POST",
        body: data,
      }),
    }),
    getUser: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/me`,
        method: "GET",
      }),
    }),
    getUserMembership: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/get-membership`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useUpdateUserMutation,
  usePostPaymentMutation,
  useGetUserMutation,
  useGetUserMembershipMutation,
  useSignupMutation,
  useWaitlistMutation,
  useCheckTokenMutation,
  useGetPrefsMutation,
  useUpdatePassMutation,
} = usersApiSlice;

export const { setPreferences, clearProfile } = profileSlice.actions;
export default profileSlice.reducer;
