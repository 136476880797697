import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HandymanIcon from "@mui/icons-material/Handyman";
import LogoutIcon from "@mui/icons-material/Logout";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BallotIcon from "@mui/icons-material/Ballot";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import React from "react";
import "../../assets/styling/profile.css";

const MobileBar = ({ switchPage }) => {
  const { account } = useSelector((state) => state.profile);
  const [current_page, setCurrentPage] = useState({
    title: "Account Info",
    icon: <ManageAccountsIcon className="side-icon" sx={{ fontSize: 30 }} />,
  });
  const [expanded, setExpanded] = useState(false);
  const tc = [
    {
      title: "Privacy",
      link: "/privacy",
    },
    {
      title: "Terms",
      link: "/terms",
    },
    {
      title: "Latest NewsLetter",
      link: "/latest",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "FAQ",
      link: "/faq",
    },

    {
      title: "Archive",
      link: "/archive",
    },
    {
      title: "Contact",
      link: "/contact",
    },
  ];
  const data = [
    {
      title: "Account Info",
      icon: <ManageAccountsIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#account",
    },
    {
      title: "Leader Persona",
      icon: <BallotIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#traits",
    },
    {
      title: "Update Password",
      icon: <HandymanIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#password",
    },
    {
      title: "Membership",
      icon: (
        <AutoAwesomeIcon
          className="side-icon"
          sx={{
            fontSize: 30,
            color: account.membership_active === true ? "#353535" : "red",
          }}
        />
      ),
      link: "#membership",
    },
    {
      title: "Logout",
      icon: <LogoutIcon className="side-icon" sx={{ fontSize: 30 }} />,
      link: "#logout",
    },
  ];

  const setSection = (item) => {
    setCurrentPage({ icon: item.icon, title: item.title });
    setExpanded(false);
    switchPage(item.link);
  };
  return (
    <div>
      <Navbar className="tbb-nav" expand="lg" expanded={expanded}>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src={"https://d2oa23hr7q6t96.cloudfront.net/base/tbbheader.jpg"}
                height="50"
                alt="The Boss Brief logo"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {data.map((item, index) => (
                <Nav.Link
                  key={index}
                  onClick={() => setSection(item)}
                  className="mobilebar-nav-link"
                >
                  {item.icon}
                  {item.title}
                </Nav.Link>
              ))}
              <hr />
              {tc.map((item, index) => (
                <LinkContainer
                  key={index}
                  className="mobilebar-tc-link"
                  to={item.link}
                  onClick={() => setExpanded(false)}
                >
                  <Nav.Link>{item.title}</Nav.Link>
                </LinkContainer>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div
        onClick={() => setExpanded(expanded ? false : "expanded")}
        className="mobile-bar"
      >
        {current_page.icon}
        {current_page.title}
      </div>
    </div>
  );
};

export default MobileBar;
