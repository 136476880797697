import React, { useState } from "react";
import FaqItem from "./FaqItem";

const quiz_faq = [
  {
    question: "How can the full report help me?",
    answer:
      "The full report analyzes over 20 business types and matches them against your profile using 12 key dimensions. You'll discover which businesses align best with your strengths and passions, giving you a clear path to success. Plus, you'll get actionable steps to automate your business for faster growth.",
  },
  {
    question: "How is the full report different from the free version?",
    answer:
      "The free version gives you a taste of how your profile aligns with different business types. The full report dives deeper into each match, highlighting strengths, weaknesses, and key opportunities. It also provides a detailed plan for automating business operations, helping you scale faster and more efficiently.",
  },
  // {
  //   question: "What happens after I purchase the full report?",
  //   answer:
  //     "Once you purchase the full report, you'll receive an email with a link to create your account. From there, you'll have access to the full report and all its insights right in your account dashboard."
  // },
  {
    question: "Is this suitable for someone with no AI experience?",
    answer:
      "Absolutely! Each module is designed for both AI newbies and seasoned pros. You can pick the path that feels right for you—whether you're just starting out or looking for more advanced tips.",
  },
  {
    question: "How practical and actionable are the insights provided?",
    answer:
      "Everything in here is designed to be used right away—no waiting around. We focus on day-to-day challenges and provide practical solutions with tools you can start using today. No fluff, no filler—just real answers to real problems you face as a business owner.",
  },
  {
    question:
      "Can I interact or ask questions if I don't understand something?",
    answer:
      "Of course! If you ever get stuck or have questions, just shoot us an email at support@thebossbrief.com, and we'll be there to help. We're here to make sure you're never left hanging.",
  },
  {
    question: "What makes this different from other AI guides or resources?",
    answer:
      "We focus on real-life improvements you can make right now—no long courses or abstract theories, just straight-to-the-point solutions you can use today.",
  },
  {
    question: "What is the refund policy?",
    answer:
      "We stand by our product, but if it's not meeting your expectations, we've got you covered. You can request a full refund within 30 days of purchase. No questions asked. Just let us know how we can do better!",
  },

  {
    question: "Are there any extra hidden costs?",
    answer:
      "No, there are no hidden fees. The price you see is a one-time payment with no renewals or additional charges.",
  },
  {
    question: "Are these materials downloadable or online only?",
    answer:
      "For now, the materials are online only. We're actively working on downloadable versions for easier access.",
  },
];

const nl_faq = [
  {
    question: "When do you send the newsletter?",
    answer:
      "You can expect the newsletter at around the morning time in the EST time zone.",
  },
  {
    question: "I'm not seeing the newsletter in my inbox, what should I do?",
    answer:
      "To make sure our emails get delivered, be sure to add brief@thebossbrief.com to your contacts. Please also check your spam folders to make sure we're not in there.",
  },
  {
    question: "Where can I go to Unsubscribe?",
    answer:
      "If you ever get tired of us, feel free to hit the unsubscribe link added to the bottom of each newsletter.",
  },
  {
    question: "How can I submit feedback?",
    answer:
      "You can find a feedback option at the bottom of every newsletter. However if you want to get in contact directly feel free to reach out to Mr. Brief at: brief@thebossbrief.com",
  },
  {
    question: "Where can I find older newsletters?",
    answer: "The archive page is coming soon! Please check back later ☺️",
  },
];

const playbook_faqData = [
  {
    question: "What exactly will I learn from these lessons?",
    answer:
      "You'll learn how to use top tools and solutions tailored to your specific needs. We'll show you when it's better to do it yourself or invest in a pre-made option, with real-world examples for each scenario. Our lessons focus on solving problems efficiently, without the overwhelm, so you can quickly apply what you learn. Plus, we're all about practical, no-fluff insights—everything you get here is designed to help you make real, immediate improvements.",
  },
  {
    question:
      "Is this suitable for someone with no prior experience with these tools?",
    answer:
      "Absolutely! Each lesson is designed for both beginners and seasoned pros. You can pick the path that feels right for you—whether you're just starting out or looking for more advanced tips. And the best part? You can switch between paths anytime, so you get the most out of the lesson no matter where you're at in your journey.",
  },
  {
    question:
      "How often is the content updated, and what new topics can I expect?",
    answer:
      "We keep things fresh with weekly updates that pull in the latest from the ever-evolving world of tools and solutions. When a new, better, faster solution comes up, we'll rework lessons to include it—ensuring you always have the best path forward. Expect topics ranging from streamlining team management to automating communications and pulling valuable insights from across the web. Got a topic you want covered? Let us know, and you might see it in the next update!",
  },
  {
    question: "How practical and actionable are the insights provided?",
    answer:
      "Everything in here is designed to be used right away—no waiting around. We focus on day-to-day challenges and provide practical solutions with tools you can start using today. No fluff, no filler—just real answers to real problems you face as a leader.",
  },
  {
    question:
      "Can I interact or ask questions if I don't understand something?",
    answer:
      "Of course! If you ever get stuck or have questions, just shoot us an email at support@thebossbrief.com, and we'll be there to help. Plus, each lesson has a discussion section where you can ask questions, see what others are asking, and get answers directly from our team and the community. We're here to make sure you're never left hanging.",
  },
  {
    question: "How much time will I need to dedicate?",
    answer:
      "We know your time is precious. Each lesson takes 15 minutes or less to get through, and you can dive deeper into implementing the solutions at your own pace. Whether you tweak things a little or go all in, the lessons are designed to fit your schedule.",
  },
  {
    question: "What makes this different from other guides or resources?",
    answer:
      "We focus on real-life improvements you can make right now—no long courses or abstract theories, just straight-to-the-point solutions you can use today.\n\nPlus, we're constantly updating our content; tools and technologies don't stay the same, so neither do we. Our lessons are easy to understand, even if you're new to this, and you'll have access to real examples and interactive exercises. It's a guide that evolves with you.",
  },
  {
    question: "Can I suggest new topics or problems to be covered?",
    answer:
      "Definitely! We love hearing from our members about what's next on their minds. You can suggest new topics or challenges you'd like us to cover, and you can also vote on upcoming lessons we're planning to add. Your feedback helps shape the lessons, making them even more valuable for everyone.",
  },
  {
    question:
      "How do the interactive exercises work? Do I need any special tools or software?",
    answer:
      "No special tools needed! All our lessons are fully online and accessible from your computer or phone. Whenever a lesson involves a tool that does need something extra, we'll clearly outline what's required so you're never caught off guard.",
  },
  {
    question: "How can I cancel my membership?",
    answer:
      "Canceling is super easy. Just go to your Account, head to the Billing section, and you're all set. No hoops to jump through!",
  },
  {
    question: "What is the refund policy?",
    answer:
      "We stand by our product, but if it's not meeting your expectations, we've got you covered. You can request a full refund within 30 days of purchase. After that, you can still get a prorated refund for any remaining months—no questions asked. Just let us know how we can do better!",
  },
];

const FaqSection = ({ nl }) => {
  const [openIndex, setOpenIndex] = useState(null);
  let faqUse = quiz_faq;
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const NLSection = () => {
    return (
      <div>
        <div
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="section-title"
        >
          Leadership Newsletter FAQs
        </div>
        {nl_faq.map((item, index) => (
          <FaqItem
            key={index}
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="ai-faq">
      <div style={{ textAlign: "center" }} className="section-title">
        {nl ? "Business Quiz FAQs" : "Frequently Asked Questions"}
      </div>
      {faqUse.map((item, index) => (
        <FaqItem
          key={index}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
          question={item.question}
          answer={item.answer}
        />
      ))}
      {nl ? <NLSection /> : null}
    </div>
  );
};

export default FaqSection;
