import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styling/profile.css";
import { trimDate } from "../../utils/helpful";
import { Button, Card, Modal } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import CCInfo from "./CCInfo";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const Membership = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { account } = useSelector((state) => state.profile);
  const upgrade_modal = {
    title: "Upgrade To Premium",
    middle: "",
    button1: "Continue",
    button2: "Cancel",
  };
  const cancel_modal = {
    title: "Edit Membership",
    middle:
      "Click 'continue' below to be taken to a secure page on Stripe where you can manage your payment information.",
    button1: "Continue",
    button2: "Cancel",
  };
  const [modalOptions, setModalOptions] = useState(upgrade_modal);
  const nextModal = () => {
    setShowConfirmation(true);
  };

  useEffect(() => {
    if (account.membership_active === true) {
      setModalOptions(cancel_modal);
    }
  }, []);

  const hideModal = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="membership">
      <p className="membership-section">
        <span>email: </span>
        {account.email}
      </p>
      <p className="membership-section">
        <span>Plan: </span>
        {account.plan}
      </p>
      <p className="membership-section">
        <span>Status:</span>
        {account.membership_active ? (
          <span style={{ background: "#bfffb9" }} className="status">
            Active
          </span>
        ) : (
          <span style={{ background: "#ffcccc" }} className="status">
            Inactive
          </span>
        )}
      </p>
      <p className="membership-section">
        <span>Sign Up Date: </span>
        {trimDate(account.createdAt)}
      </p>

      <p className="membership-section">
        <span>Trial Period: </span>
        {account.trial_status}
      </p>
      {account.membership_active === true ? (
        <Button variant="secondary" onClick={() => nextModal()}>
          Edit Membership
        </Button>
      ) : (
        <Button variant="secondary" onClick={() => nextModal()}>
          {account.trial_status === "not started"
            ? "Try Premium Free"
            : "Upgrade to Premium"}
        </Button>
      )}
      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalOptions.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {account.membership_active === true ? (
            modalOptions.middle
          ) : (
            <CCInfo />
          )}
        </Modal.Body>
        <Modal.Footer>
          {account.membership_active === true ? (
            <a
              href={
                "https://billing.stripe.com/p/login/5kAeWM1Ld3l35wY7ss" +
                "?client_reference_id=" +
                account.user
              }
              className="btn btn-primary"
              role="button"
              rel="noopener noreferrer"
            >
              Continue
            </a>
          ) : (
            <a
              href={
                "https://buy.stripe.com/28ocQS0rP4WM3Ly3cc" +
                "?client_reference_id=" +
                account.user
              }
              className="btn btn-primary"
              role="button"
              rel="noopener noreferrer"
            >
              Continue
            </a>
          )}

          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Membership;
