export const shuffleWord = (word) => {
  const characters = word.split("");
  for (let i = characters.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [characters[i], characters[j]] = [characters[j], characters[i]];
  }
  return characters.join("");
};
export const shuffleString = (inputString) => {
  const hasEllipsis = inputString.endsWith("...");
  const inputWithoutEllipsis = hasEllipsis
    ? inputString.slice(0, -3)
    : inputString;
  const words = inputWithoutEllipsis.split(" ");
  const shuffledWords = words.map((word) => shuffleWord(word));
  const shuffledString = shuffledWords.join(" ");
  return hasEllipsis ? shuffledString + "..." : shuffledString;
};
