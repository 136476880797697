import React from "react";

const Advertise = () => {
  return (
    <div className="faq">
      <h1>Advertise</h1>

      <p style={{ fontWeight: "400", marginBottom: "10px" }} className="faq-q">
        For advertising or business partnerships please reach out to us here:
      </p>
      <ul>
        <li className="faq-a">
          <span>Advertising</span>: <b>partnerships@thebossbrief.com</b>
        </li>
      </ul>
    </div>
  );
};

export default Advertise;
