import React from "react";
import "../assets/styling/misc.css";
import FaqSection from "../components/Playbook/FaqSection";

const FAQScreen = () => {
  return (
    <div>
      <FaqSection nl={true} />
    </div>
  );
};

export default FAQScreen;
