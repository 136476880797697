import React, { useState, useEffect, useRef } from "react";
import "../../assets/styling/hero.css";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { combo } from "../../utils/home_rotation";

const SignUpDos = ({ version, campaign }) => {
  const navigate = useNavigate();

  const [err_statement, setStatement] = useState(
    "Error... please try again later"
  );

  const [page_content, setPage] = useState(combo[version]);

  const startQuiz = () => {
    navigate("/find-your-business-fit?start=1");
  };
  const bolding = (entry, type) => {
    const compiled = [];
    entry.split("[[").forEach((segment, index) => {
      if (segment.includes("]]")) {
        const parts = segment.split("]]");
        if (type === "bold") {
          compiled.push(<b key={index}>{parts[0]}</b>);
          compiled.push(parts[1]);
        }
      } else {
        compiled.push(segment);
      }
    });
    return compiled;
  };

  const err_msg = <span className="err-msg">{err_statement}</span>;
  return (
    <div style={{ marginTop: "1em", textAlign: "center" }}>
      <div id="get-mine" className="signup-dos">
        <div>
          <p style={{ marginBottom: "1rem" }} className="section-title">
            {page_content.titleSection2}
          </p>

          <div
            style={{ margin: "auto" }}
            className="quiz-home-link"
            onClick={startQuiz}
          >
            {page_content.action1} <ArrowForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpDos;
