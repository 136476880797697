import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetSlideDataMutation } from "../slices/newsletterApiSlice";
import { setData } from "../slices/newsletterApiSlice";
import { Link } from "react-router-dom";
import axios from "axios";
import "../assets/styling/feed.css";

const Feed = () => {
  const [loading, setLoading] = useState(false);
  const [viewing, setViewing] = useState([]);
  const [err, setErr] = useState(false);

  const dispatch = useDispatch();

  const [getRecent, { isLoading }] = useGetSlideDataMutation();
  const { slide_data } = useSelector((state) => state.newsletter);
  useEffect(() => {
    setLoading(true);
    loadRecent();
  }, []);

  const loadRecent = async () => {
    setLoading(true);
    try {
      //check redux first
      if (slide_data.length > 0) {
        setViewing(slide_data);
        setLoading(false);
      } else {
        //go to db
        const res = await axios.get("/api/newsletter/recent-list");
        if (res.err || res.data.err) {
          setErr(true);
        } else {
          setViewing(res.data.recent);
          dispatch(setData(res.data.recent));
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("err");
    }
  };
  if (loading || err) {
    return null;
  }

  return (
    <div className="feed">
      <p className="feed-recent">
        Recent Newsletters {/* Search 'Reinstate Archive' to find all */}
        <span>
          | <Link to="/archive">View Archive</Link>
        </span>
      </p>
      {viewing.map((item, index) => (
        <Link key={index} to={"/view?nl=" + item.id}>
          <div className="feed-box">
            <img src={item.img} className="feed-img" />
            <div className="feed-info">
              <p className="feed-title">{item.title}</p>
              <p className="feed-date">{item.date}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Feed;
