import React from "react";
import Hero from "../components/Hero";
import Slider from "../components/Slider";
import Feed from "../components/Feed";

const Homescreen = () => {
  return (
    <div>
      <Hero />
      <div className="separator">
        <span className="q">❝</span> So insightful, so funny. Really wished I
        had this years ago, it's a great addition to my routine!{" "}
        <span className="q">❞</span>
      </div>
      <Slider />
      <Feed />
    </div>
  );
};

export default Homescreen;

//
