/*


*/
const question_map = {
  time: "q4",
  tech: "q0",
  design: "q1",
  brand: "q11",
  content: "q2",
  support: "q5",
};

const automate_mapping = [
  // 0s
  {
    question: "q0",
    answer: 0,
    identified: "not having a tech background",
  },
  {
    question: "q1",
    answer: 0,
    identified: "lack of design skills",
  },
  {
    question: "q3",
    answer: 0,
    identified: "difficulty in researching markets",
  },
  {
    question: "q2",
    answer: 0,
    identified: "struggling with content creation",
  },
  {
    question: "q5",
    answer: 0,
    identified: "managing customer service issues",
  },
  {
    question: "q6",
    answer: 0,
    identified: "discomfort with being on camera",
  },
  {
    question: "q8",
    answer: 0,
    identified: "challenges in building a community",
  },
  {
    question: "q11",
    answer: 0,
    identified: "struggles with self-promotion or branding",
  },
  {
    question: "q9",
    answer: 0,
    identified: "uncertainty in teaching or coaching others",
  },
  {
    question: "q4",
    answer: 0,
    identified: "lack of time to dedicate",
  },
  // 1s --------------
  {
    question: "q0",
    answer: 1,
    identified: "not being a technical expert",
  },
  {
    question: "q1",
    answer: 1,
    identified: "not being an expert in design",
  },
  {
    question: "q3",
    answer: 1,
    identified: "not being a market research specialist",
  },
  {
    question: "q2",
    answer: 1,
    identified: "not being a pro at content creation",
  },
  {
    question: "q5",
    answer: 1,
    identified: "not having extensive customer service experience",
  },
  {
    question: "q6",
    answer: 1,
    identified: "feeling less confident on camera",
  },
  {
    question: "q8",
    answer: 1,
    identified: "not being a seasoned community builder",
  },
  {
    question: "q11",
    answer: 1,
    identified: "not being a natural at self-promotion",
  },
  {
    question: "q9",
    answer: 1,
    identified: "wanting to enhance your teaching or coaching skills",
  },
  {
    question: "q4",
    answer: 1,
    identified: "having limited time to dedicate",
  },
  // 2s
];
const a_mapping = {
  q0: "Guide for using AI to handle your technical work",
  q1: "Guide for using AI to automate your design process",
  q2: "Guide for using AI to automate content creation",
  q3: "Guide for using AI to automate market research",
  q4: "Guide for using AI to automate time-consuming tasks",
  q5: "Guide for using AI to automate customer service",
  q6: "Use AI to create video content without appearing on camera",
  q7: "",
  q8: "Guide for using AI to automate community engagement",
  q9: "Guide for using AI to enhance your teaching methods",
  q10: "",
  q11: "Guide for using AI to automate your personal branding",
};

const b_mapping = {
  q0: "How to delegate tech needs to AI like an employee",
  q1: "How to leverage AI to handle your design needs",
  q2: "How to use AI to generate engaging content",
  q3: "How to utilize AI for market analysis",
  q4: "How AI can handle tasks to free up your schedule",
  q5: "How to employ AI to manage customer interactions",
  q6: "How to generate avatars with AI for presentations",
  q7: "",
  q8: "How to use AI to foster and manage online communities",
  q9: "How to leverage AI for effective coaching and instruction",
  q10: "",
  q11: "How to use AI for effective self-promotion and branding",
};

export const getStrengthsWording = (answers) => {
  // Find phrases matching the user's answers
  const phraseArray = automate_mapping
    .filter((mapping) => answers[mapping.question] === mapping.answer)
    .map((mapping) => b_mapping[mapping.question]);

  // Fill the rest with "Empty" if less than 3 matches

  //Create an array with 3 default phrases for each.
  while (phraseArray.length < 3) {
    phraseArray.push("Delegating To AI Like An Employee");
  }
  //   console.log(phraseArray);
  return phraseArray.slice(0, 3);
};
export const getIdentified = (answers) => {
  const default_phrase = "not having enough skills";
  const phraseArray = automate_mapping
    .filter((mapping) => answers[mapping.question] === mapping.answer)
    .map((mapping) => mapping.identified);

  return phraseArray.length > 0 ? phraseArray[0] : default_phrase;
};

export const getTop3Phrases = (company) => {
  // Extract relevant attributes from the company that are in question_map
  const relevantAttributes = Object.keys(company)
    .filter((attr) => attr in question_map)
    .map((attr) => ({ attribute: attr, score: company[attr] }));

  // Sort the attributes by their scores in descending order
  relevantAttributes.sort((a, b) => b.score - a.score);

  // Get the top 3 attributes
  const top3Attributes = relevantAttributes.slice(0, 3);

  // Map the top attributes to their corresponding phrases
  const phrases = top3Attributes.map(({ attribute }) => {
    const questionCode = question_map[attribute];
    return a_mapping[questionCode];
  });

  return phrases;
};
