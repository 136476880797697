import React from "react";
import SubHero from "../components/SubHero";

const SubscribeLanding = () => {
  return (
    <div>
      <SubHero />
    </div>
  );
};

export default SubscribeLanding;
