import React, { useState, useEffect } from "react";
import PlaybookHero from "../../components/Playbook/PlaybookHero";
import Checklist from "../../components/Playbook/Checklist";
import FaqSection from "../../components/Playbook/FaqSection";
import QuoteSection from "../../components/Playbook/QuoteSection";
import SignUpDos from "../../components/Playbook/SignUpDos";
import Stats from "../../components/Playbook/Stats";
import { Container } from "react-bootstrap";
import { combo } from "../../utils/home_rotation";
import HomeFooter from "../../components/HomeFooter";
import QuizHomeHero from "../../components/BizQuiz/QuizHomeHero";

const QuizHomeScreen = () => {
  const [version, setVersion] = useState("v1");
  const [campaign, setCampaign] = useState("de");
  const [loading, setLoading] = useState(false);
  const page_content = combo.v1;

  useEffect(() => {}, []);

  if (loading) return null;
  return (
    <div style={{ position: "absolute", left: "0", width: "100%" }}>
      <Container fluid="lg">
        <QuizHomeHero version={version} campaign={campaign} />
      </Container>
      <div>
        <img className="home-img" src="/images/homepage7.jpg" />
      </div>

      {/* <div className="separator-ai">
        {version === "plbai" ? (
          <div className="ai-logos" />
        ) : (
          <div className="guide-separator">How We Help</div>
        )}
      </div> */}
      <Container style={{ textAlign: "center" }} fluid="lg">
        {version === "v1" ? (
          <div className="section-title">{page_content.group_title}</div>
        ) : null}

        <Checklist version={version} campaign={campaign} />
      </Container>

      <div style={{ textAlign: "center" }} className="separator-ai">
        <QuoteSection version={version} />
      </div>
      <Container style={{ textAlign: "center" }} fluid="lg">
        <Stats version={version} campaign={campaign} />
      </Container>
      <div
        className="separator-ai"
        style={{ background: "#f6f9fc", marginBottom: "20rem" }}
      >
        <SignUpDos version={version} campaign={campaign} />
      </div>

      <HomeFooter />
    </div>
  );
};

export default QuizHomeScreen;
