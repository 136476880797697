import React, { useState, useEffect } from "react";
import loader from "../assets/lottie/heart.json";
import Lottie from "lottie-react";
import "../assets/styling/misc.css";
import NotFound from "../appwide/NotFound";
import { useLocation } from "react-router-dom";
import { useCheckTokenMutation } from "../slices/usersApiSlice";
import { shuffleString } from "../utils/shuffleWord";

const Feedback = () => {
  const [valid, setValid] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [scrambled, setScramble] = useState("");
  const [scramble_hint, setHint] = useState("");
  const [scramble_answer, setAnswer] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsletter = searchParams.get("nl");
  const date = searchParams.get("date");
  const token = searchParams.get("utk");
  const feedback_type = searchParams.get("ft");
  const [check, { isLoading }] = useCheckTokenMutation();

  useEffect(() => {
    checkUser();
  }, [location]);

  const checkUser = async () => {
    try {
      const res = await check({
        token,
        newsletter,
        date,
        feedback_type,
      }).unwrap();

      if (res.valid) {
        setValid(true);
      }

      const scram = shuffleString(res.scramble.toLowerCase());
      setAnswer(res.scramble);
      setHint(res.hint);
      setScramble(scram);
      setResolved(true);
    } catch (error) {
      console.log("error");
      setValid(false);
      setResolved(true);
    }
  };
  const officeLingo = (
    <div className="scramble-section">
      <p>
        If not, here's some scrambled office lingo to get you back into "work
        mode"😉
      </p>
      <div className="scrambled">{scrambled}</div>
      <div>
        <div onClick={() => setShowHint(true)} className="hint">
          Hint
        </div>{" "}
        <span>{showHint ? scramble_hint : null}</span>
      </div>
      <div>
        <div onClick={() => setShowAnswer(true)} className="answer">
          Answer
        </div>
        <span>{showAnswer ? scramble_answer : null}</span>
      </div>
    </div>
  );
  const data = (
    <>
      <div className="feedback-top">
        <Lottie
          animationData={loader}
          loop={true}
          style={{
            height: "250px",
            margin: "1em",
            display: "block",
          }}
        />
        <div className="feedback-thanks">
          <p
            style={{ fontFamily: "Anton", fontSize: "2em", lineHeight: "1.2" }}
          >
            We appreciate the feedback!
          </p>
          <p style={{ marginBottom: "1px" }}>
            If you want to elaborate a bit more, please check out the form
            below.
          </p>
          {officeLingo}
        </div>
      </div>
      <iframe
        src={
          "https://docs.google.com/forms/d/e/1FAIpQLSdDNlYX7e2A_CsxigeBk45Jw1LpjbBNc1NdrgzaS809hZM0BA/viewform?usp=pp_url&entry.1904615672=" +
          date +
          "&embedded=true"
        }
        height="586"
        className="google-form"
      >
        Loading…
      </iframe>
    </>
  );
  if (!resolved) return null;
  return (
    <div>
      {valid ? data : <NotFound />}
      <div style={{ height: "5em" }} />
    </div>
  );
};

export default Feedback;
