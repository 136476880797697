import axios from "axios";

export const logClick = async (version, campaign, type) => {
  try {
    const res = await axios.post("/api/users/clicked-quiz", {
      version,
      campaign,
      type,
    });
    // console.log(
    //   "logged click for [" + type + "] v:" + version + " & camp:" + campaign
    // );
    return;
  } catch (error) {
    console.error("Error logging click:", error);
    throw error; // Rethrow the error to handle it in the component
  }
};
