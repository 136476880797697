import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useGetLatestMutation } from "../slices/newsletterApiSlice";
import { setSingle } from "../slices/newsletterApiSlice";
import AppHadError from "../appwide/AppHadError";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../assets/styling/misc.css";

const ViewSingle = () => {
  const [loading, setLoading] = useState(false);
  const [viewing, setViewing] = useState({});
  const [recent, setRecent] = useState([]);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();

  const [getlatest, { isLoading }] = useGetLatestMutation();
  const { single, slide_data } = useSelector((state) => state.newsletter);
  useEffect(() => {
    setLoading(true);
    loadSingle(searchParams.get("nl"));
  }, []);

  useEffect(() => {
    const tableElements = document.querySelectorAll(
      'table[data-tbb="tbb-footer"]'
    );

    // Get the last table element.
    const lastTableElement = tableElements[tableElements.length - 1];

    // Check if the lastTableElement exists before removing it.
    if (lastTableElement) {
      lastTableElement.remove();
    }
  }, [viewing]);
  const loadSingle = async (nl_id) => {
    setLoading(true);
    try {
      //check redux first
      if (single[nl_id]) {
        setViewing(single[nl_id]);
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
        setLoading(false);
      } else {
        //go to db
        const res = await axios.post("/api/newsletter/single", { id: nl_id });
        setViewing(res.data);
        if (res.err || res.data.err) setErr(true);
        setLoading(false);
        dispatch(setSingle({ id: nl_id, data: res.data }));
        if (res.data.html === "") navigate("/404");
      }
      // add recent below
      if (slide_data.length > 0) {
        setRecent(slide_data);
      }
    } catch (err) {
      console.log("err");
    }
  };
  if (loading) {
    return <Loader h={"15em"} m={"0"} w={"100%"} />;
  }

  return (
    <div>
      {err ? (
        <AppHadError />
      ) : (
        <div>
          <div className="latest-header">
            <p className="title">{viewing.subject}</p>
            <p className="date">{viewing.send_date}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: viewing.html,
            }}
          />
          <hr style={{ maxWidth: "620px", margin: "auto" }} />
          <div className="latest-footer">
            <p style={{ margin: "0" }}>
              <Link to="/sublanding">
                <span>Subscribe</span>
              </Link>{" "}
              to get these in your inbox!
            </p>
          </div>
          <div>
            <p className="f-single-recent">
              <span style={{ width: "75%", display: "inline-block" }}>
                Recent Newsletters{" "}
              </span>
              {/* Search 'Reinstate Archive' to find all */}
              <span
                style={{
                  width: "25%",
                  textAlign: "right",
                  display: "inline-block",
                }}
              >
                <Link className="archive-link" to="/archive">
                  archive
                </Link>
              </span>
            </p>
            {recent.map((item, index) => (
              <div
                key={index}
                onClick={() => loadSingle(item.id)}
                className="f-single-box"
              >
                <img src={item.img} className="f-single-img" />
                <div className="f-single-info">
                  <p className="feed-title">{item.title}</p>
                  <p className="feed-date">{item.date}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSingle;
