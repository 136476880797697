import React, { useState, useEffect, useRef } from "react";
import { combo } from "../../utils/home_rotation";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { setPreloadedImage } from "../../utils/helpful";

const QuizHomeHero = ({ version, campaign }) => {
  const [page_content, setPage] = useState(combo["v1"]);
  const [campaign_img, setCampaign] = useState("");
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const startQuiz = () => {
    // setPreloadedImage(
    //   "https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbdevices.gif"
    // );
    navigate("/find-your-business-fit?start=1");
  };
  const bolding = (entry, type) => {
    const compiled = [];
    entry.split("[[").forEach((segment, index) => {
      if (segment.includes("]]")) {
        const parts = segment.split("]]");
        if (type === "bold") {
          compiled.push(<b key={index}>{parts[0]}</b>);
          compiled.push(parts[1]);
        }
      } else {
        compiled.push(segment);
      }
    });
    return compiled;
  };

  return (
    <div>
      <div style={{ marginTop: "5em" }} className="leader-guide-hero">
        <div className="quiz-home-hero" style={{ textAlign: "center" }}>
          <div>
            <p className="hero-title">
              {page_content.bold_title.title}
              <span style={{ textDecoration: "underline 8px #d49960" }}>
                {page_content.bold_title.span}
              </span>
              {page_content.bold_title.title2}
            </p>

            <p className="quiz-home-subhead">
              <span style={{ display: "inline-block" }}>
                {bolding(page_content.value_blurb, "bold")}
              </span>
            </p>

            <div className="quiz-home-link" onClick={startQuiz}>
              {page_content.heroAction} <ArrowForwardIcon />
            </div>
          </div>
        </div>
        <div className="hero-right-ai">
          <div>
            {/* <img className="hero-img" src={page_content.hero_img} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizHomeHero;
