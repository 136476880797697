import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./appwide/ErrorBoundary";
import { Container } from "react-bootstrap";
import CookieConsent from "./components/CookieConsent";
import useSparkloopScript from "./utils/useSparkloopScript";

const App = () => {
  useSparkloopScript();
  return (
    <>
      <ErrorBoundary>
        <Header />
        <Container fluid="lg">
          <Outlet />
        </Container>
        <Footer />
        <CookieConsent />
      </ErrorBoundary>
    </>
  );
};

export default App;
