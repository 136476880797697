import React, { useState, useEffect } from "react";

import { combo, stat_base } from "../../utils/home_rotation";

const Stats = ({ version }) => {
  const [page_content, setPage] = useState(combo["v1"]);
  const [stat_content, setStats] = useState(stat_base);

  return (
    <div>
      <p className="section-title">{page_content.why_now_title}</p>
      <p className="section-intro">{page_content.why_now_intro}</p>
      <div className="stat-squares">
        {stat_content.map((item, index) => {
          return (
            <div key={index} className="stat-square">
              <img src={item.img} />
              <p className="stat-square-stat">{item.stat}</p>
              <p className="stat-square-desc">{item.context}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stats;
