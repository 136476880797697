import React from "react";
import loader from "../assets/lottie/loader.json";
import Lottie from "lottie-react";

const Loader = ({ h, m, w }) => {
  return (
    <div>
      <Lottie
        animationData={loader}
        loop={true}
        style={{
          height: h,
          margin: m,
          width: w,
        }}
      />
    </div>
  );
};

export default Loader;
