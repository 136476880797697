import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "../../assets/styling/playbook.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import { combo } from "../../utils/home_rotation";

const Checklist = ({ version }) => {
  const [page_content, setPage] = useState(combo["v1"]);
  useEffect(() => {}, []);
  return (
    <Box className="center-container">
      <Box className="checklist-container">
        <div className="step-box">
          <Box className="step-number">1</Box>
          <Box className="sub-line" />
        </div>
        <div className="step-info step-info-left">
          <div className="step-details">
            <img
              style={{ width: "100%", marginTop: "-1em" }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbcheck.gif"
            />
          </div>
          <div className="step-details">
            <p className="step-text">{page_content.step1.title}</p>
            <p className="step-text-desc">{page_content.step1.text}</p>
          </div>
          <Box className="line"></Box>
        </div>

        <div className="step-box">
          <Box className="step-number">2</Box>
          <Box className="sub-line" />
        </div>
        <div className="step-info step-info-right">
          <div className="step2-mobile step-details">
            <img
              style={{ width: "100%" }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbplan.gif"
            />
          </div>
          <div className="step-details">
            <p className="step-text">{page_content.step2.title}</p>
            <p className="step-text-desc">{page_content.step2.text}</p>
          </div>
          <div className="step2-full step-details">
            <img
              style={{ width: "100%" }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbplan.gif"
            />
          </div>
          <Box className="line"></Box>
        </div>

        <div className="step-box">
          <Box className="step-number">3</Box>
          <Box className="sub-line" />
        </div>

        <div className="step-info step-info-left">
          <div className="step-details">
            <img
              style={{ width: "100%", marginTop: "-1em" }}
              src="https://d2oa23hr7q6t96.cloudfront.net/playbook/tbbdevices.gif"
            />
          </div>
          <div className="step-details">
            <p className="step-text">{page_content.step3.title}</p>
            <p className="step-text-desc">{page_content.step3.text}</p>
          </div>
          <VerifiedIcon sx={{ fontSize: 40 }} className="finish-line" />
        </div>
      </Box>
    </Box>
  );
};

export default Checklist;
