import React from "react";
import "../assets/styling/404.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-here">
      <p className="top">404</p>
      <p>
        <b>That page doesn't exist out here unfortunately...</b>
      </p>
      <img src="https://d2oa23hr7q6t96.cloudfront.net/base/404.jpg" />
      <p style={{ marginTop: "1em" }}>
        If you're looking to sign up for the newsletter{" "}
        <Link to="/sublanding">
          <strong>go here!</strong>
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
