const combo = {
  v1: {
    title: "Leadership news that's ",
    span: "smart & fun",
    title2: "!",
    p1: "Get the latest in leadership news and trends with a",
    strong: " free newsletter every Monday!",
    p2: " Designed for time-strapped managers, we keep things informative, enjoyable and brief. 😉",
    pic: "/images/Tornado01.jpg",
  },
  v12: {
    title: "Leadership news that's ",
    span: "smart & fun",
    title2: "!",
    p1: "Get the latest in leadership news and trends with a",
    strong: " free newsletter every Monday!",
    p2: " Designed for time-strapped managers, we keep things informative, enjoyable and brief. 😉",
    pic: "/images/Carouselpic.png",
  },
  v13: {
    title: "Leadership news that's ",
    span: "smart & fun",
    title2: "!",
    p1: "Get the latest in leadership news and trends with a",
    strong: " free newsletter every Monday!",
    p2: " Designed for time-strapped managers, we keep things informative, enjoyable and brief. 😉",
    pic: "/images/VR1.jpg",
  },
  v14: {
    title: "Leadership news that's ",
    span: "smart & fun",
    title2: "!",
    p1: "Get the latest in leadership news and trends with a",
    strong: " free newsletter every Monday!",
    p2: " Designed for time-strapped managers, we keep things informative, enjoyable and brief. 😉",
    pic: "/images/Youth1.jpg",
  },
  v2: {
    title: "A ",
    span: "smart & fun",
    title2: " leadership digest!",
    p1: "Get the latest in leadership news and trends with a",
    strong: " free newsletter every Monday!",
    p2: " Designed for time-strapped managers, we keep things informative, enjoyable and brief.",
    pic: "/images/FLHCircle.jpeg",
  },

  v3: {
    title: "News for ",
    span: "smart & spirited",
    title2: " leaders!",
    p1: "Get our ",
    strong: "free newsletter every Monday!",
    p2: " Designed for top-notch leaders, we keep things informative, enjoyable and brief.",
    pic: "/images/FLHCircle.jpeg",
  },
};

export const landing = (version) => {
  return combo[version];
};
