import React, { useEffect } from "react";
import "../assets/styling/footer.css";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { pathsToCheck } from "../utils/helpful";

const HomeFooter = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    // Using setTimeout to ensure the scroll happens after the component mounts
    const handleScrollToTop = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    };

    handleScrollToTop();
  }, [location]);

  return (
    <div>
      <div className="outer">
        <Row className="links">
          {/* <Col>
          <p>Social</p>
          <p>TT</p>
          <p>YT</p>
        </Col> */}

          <Col>
            <Link to="/leadership-newsletter">
              <p>Check Out Our Leadership Newsletter!</p>
            </Link>
          </Col>
          <Col>
            <Link to="/contact">
              <p>Contact Us</p>
            </Link>
            <Link to="/faq">
              <p>FAQ</p>
            </Link>
          </Col>
          <Col>
            <Link to="/privacy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/terms">
              <p>Terms of Service</p>
            </Link>
          </Col>
        </Row>
      </div>
      <p className="copyright">
        Copyright © 2024 The Boss Brief. All rights reserved
      </p>
    </div>
  );
};

export default HomeFooter;
