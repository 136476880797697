const bar_colors = {
  0: "#4E7BE5",
  1: "#FF9684",
  2: "#24C196",
  3: "#8D65C2",
  4: "#DE4B63",
  5: "#9FA8DF",
  6: "#EEE8A9",
  7: "#C1A875",
};
const bar_colors_list = [
  "#4E7BE5",
  "#FF9684",
  "#24C196",
  "#8D65C2",
  "#DE4B63",
  "#9FA8DF",
  "#EEE8A9",
  "#C1A875",
  "#85dff4",
  "#f4ba85",
];
const selective_bar_colors = [
  "#4E7BE5", //blue
  "#DE4B63", //red
  "#24C196", //green
  "#FF9684", //salmon
  "#8D65C2", //purple
  "#ec7c16", //orange
];

export const barColor = (index) => {
  return bar_colors[index];
};

export const shuffleColors = () => {
  return selective_bar_colors.sort(() => Math.random() - 0.5);
};

export const simColor = (count) => {
  if (count >= 10) return { color: "#009EFA", wording: "People have" };
  if (count >= 5) return { color: "#00C9A7", wording: "People have" };
  if (count > 1) return { color: "#b6d034", wording: "People have" };
  if (count === 1) return { color: "#cfa525", wording: "Person has" };
  if (count === 0) return { color: "#c9495d", wording: "People have" };
};
export const pathColor = (count) => {
  if (count >= 100) return "#00e393";
  if (count >= 80) return "#c6f18d";
  if (count >= 70) return "#F9F871";
  if (count >= 55) return "#D4A518";
  if (count >= 30) return "#f18797";
  if (count >= 1) return "#ff3434";
  return "#d7d7d7";
};

export const correctOpacity = (base_color, position, total_positions) => {
  if (position === 0) return base_color;

  // Remove the '#' from the beginning of the hex color if present
  if (base_color.charAt(0) === "#") {
    base_color = base_color.substring(1);
  }

  // Convert the hex color to RGB
  let r = parseInt(base_color.substring(0, 2), 16);
  let g = parseInt(base_color.substring(2, 4), 16);
  let b = parseInt(base_color.substring(4, 6), 16);

  // Calculate the alpha value with a floor of 0.45 and ceiling of 1
  let minAlpha = 0.35;
  let maxAlpha = 1;
  let alphaRange = maxAlpha - minAlpha;
  let alpha = (
    maxAlpha -
    (position / (total_positions - 1)) * alphaRange
  ).toFixed(2);

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
