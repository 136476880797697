// src/components/ProgressBar.js
import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      light: "#fff",
      dark: "#333",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
});

function ProgressBar({ current, total }) {
  const progress = (current / total) * 100;
  return (
    <ThemeProvider theme={theme}>
      <Box
        margin={"0 10px"}
        display="flex"
        alignItems="center"
        style={{
          background: "#0000004f",
          borderRadius: "10px",
          padding: "2px 5px",
        }}
      >
        <Box width="100%">
          <LinearProgress
            style={{
              height: "15px",
              borderRadius: "10px",
              background: "#ffffff63",
            }}
            variant="determinate"
            value={progress}
            color="primary"
          />
        </Box>
        <Box minWidth={35}>
          <Typography
            style={{ textAlign: "right" }}
            variant="body2"
            color="white"
          >{`${Math.round(progress)}%`}</Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ProgressBar;
