export const judgePasswordStrength = (password) => {
  let strength = 0;

  // Length
  if (password.length >= 8) {
    strength += 1;
  }
  if (password.length >= 12) {
    strength += 1;
  }

  // Character Types
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigits = /[0-9]/.test(password);
  const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

  // Increase strength for different character types
  if (hasUpperCase) {
    strength += 1;
  }
  if (hasLowerCase) {
    strength += 1;
  }
  if (hasDigits) {
    strength += 1;
  }
  if (hasSpecialChar) {
    strength += 1;
  }

  // Check for common patterns (e.g., '12345', 'abcde')
  const commonPatterns = /12345|abcde|password|qwerty/.test(password);
  if (commonPatterns) {
    strength -= 1;
  }

  // Determine strength level
  if (strength <= 1) {
    return { label: "Weak", percentage: { width: "25%", color: "#ff3434" } };
  } else if (strength === 2 || strength === 3) {
    return { label: "Ok", percentage: { width: "50%", color: "#ffc107" } };
  } else if (strength === 4 || strength === 5) {
    return { label: "Strong", percentage: { width: "75%", color: "#c6f18d" } };
  } else if (strength >= 6) {
    return {
      label: "Excellent",
      percentage: { width: "100%", color: "#00e393" },
    };
  }
};
