import React from "react";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";

export const MoneyGroup = ({ amount }) => {
  if (amount <= 3) {
    return (
      <div className="money-g">
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
      </div>
    );
  }
  if (amount <= 6) {
    return (
      <div className="money-g">
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
      </div>
    );
  }
  if (amount <= 8) {
    return (
      <div className="money-g">
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
      </div>
    );
  }
  if (amount <= 10) {
    return (
      <div className="money-g">
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
        <div>
          <MonetizationOnTwoToneIcon sx={{ fontSize: 40, color: "#727272" }} />
        </div>
      </div>
    );
  }
  return (
    <div>
      <MonetizationOnTwoToneIcon />
    </div>
  );
};
