import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeaderPrefs from "./Profile/LeaderPrefs";
import { Container, Button, Row, Col } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CCInfo from "./Profile/CCInfo";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const CompleteRegistration = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [step, setStep] = useState({
    header: "Reminder of your leadership attributes",
    subheader: "*These can be changed after finish setting up your account",
    page: "prefs",
  });
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    //get leader pref
  }, []);
  const finishUp = () => {
    const updated_step = {
      header: "You're Ready to Go!",
      subheader:
        "Look out for a personalized action plan in your next newsletter.",
      page: "done",
      success: true,
    };
    const updated_step_fail = {
      header: "Something went wrong.",
      subheader:
        "We weren't able to add a card to your account. Please go to your dashboard and try again.",
      page: "done",
      failed: true,
    };
    setStep(updated_step);
    setFinished(true);
  };

  const setupCC = () => {
    const updated_step = {
      header: "Payment Info",
      subheader: "Next we'll take care of your payment details",
      page: "cc",
    };
    setStep(updated_step);
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={9} lg={7} className="card p-5 reg-form">
          <div className="reg-header">{step.header}</div>
          <p style={{ color: "grey", fontSize: "0.87rem" }}>{step.subheader}</p>
          {finished ? (
            <div className="reg-finished">Finished</div>
          ) : (
            <>
              {" "}
              {step.page === "prefs" ? (
                <>
                  {" "}
                  <LeaderPrefs show_type={"simple"} />
                  <Button
                    variant="primary"
                    onClick={() => setupCC()}
                    className="mt-3 reg-next"
                  >
                    Next <ArrowForwardIcon />
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  <CCInfo />
                  <a
                    href={
                      "https://buy.stripe.com/28ocQS0rP4WM3Ly3cc" +
                      "?client_reference_id=" +
                      userInfo._id
                    }
                    className="btn btn-primary mt-3 reg-next"
                    role="button"
                    rel="noopener noreferrer"
                  >
                    Enter Details <CreditCardIcon />
                  </a>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CompleteRegistration;
