import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";

import Loader from "../components/Loader";

import React from "react";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [issue, setIssue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo && ["premium", "access"].includes(userInfo.type)) {
      navigate("/profile");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      if (res && res.problem) {
        setIssue(res.problem);
      } else {
        dispatch(setCredentials({ ...res }));
        navigate("/profile");
      }
    } catch (error) {
      console.log("error");
    }
  };
  if (isLoading) return <Loader h={"12em"} m={"0"} w={"100%"} />;
  return (
    <div>
      <FormContainer>
        <p className="login-reg-header">Premium Account Login</p>
        <p
          style={{
            color: "grey",
            fontSize: "0.87rem",
            margin: "0 0 1em",
            paddingBottom: "5px",
          }}
        ></p>
        <Form onSubmit={submitHandler}>
          <Form.Group className="my-2" controlId="email">
            <Form.Label className="reg-label">Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              className="reg-input"
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="my-2" controlId="password">
            <Form.Label className="reg-label">Password</Form.Label>
            <Form.Control
              type="password"
              className="reg-input"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
          <Button
            disabled={email === "" || password === "" ? true : false}
            type="submit"
            variant="primary"
            className="mt-3 reg-submit"
          >
            Sign in
          </Button>
        </Form>
        <div style={{ fontSize: "13px", textAlign: "center" }}>
          <Link to={"/resetpass"}>
            <span>Forgot Password</span>
          </Link>
        </div>
      </FormContainer>
    </div>
  );
};

export default LoginScreen;
