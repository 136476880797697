import React, { useState, useEffect } from "react";
import "../assets/styling/slider.css";
import { Link } from "react-router-dom";
const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const imageUrls = [
    "https://d2oa23hr7q6t96.cloudfront.net/652f7c930597956ce10421de/N/fb6b243306a8b3c89a52351a696a42302483856e0133e660ef091eba7f740841",
    "https://d2oa23hr7q6t96.cloudfront.net/6532234ac660c23544334d27/Tr/6bc62a1e0585b0427633a7fc5099441031eb621d124a7c3e7c4159c0f9ad0951",
    "https://d2oa23hr7q6t96.cloudfront.net/652875970597956ce1e9e49e/To/c235e8b91b84c55de7afcb58ca79621826d02195fe32c58ed768ee3b8c325da5",
    "https://d2oa23hr7q6t96.cloudfront.net/653659f4c660c23544c014e2/Lc/c07b24a639f2de790c534139a19a05f549c170cc7299583768f687e233f549ef",
    "https://d2oa23hr7q6t96.cloudfront.net/65394ccbc660c2354454da78/To/1fc7d52b3f45bdbfd5a80d8e7d4d5fe798339c7660b7157163b7cc98e6b7c6d5",
  ];
  const content = [
    {
      title: "📰 Across the Interwebs",
      notes: [
        "• The latest management studies",
        "• Strategies to implement with your team",
        "• Work areas to be cautious of",
      ],
    },
    {
      title: "☕️ Word on the Street",
      notes: [
        "• Trending employee topics and concerns",
        "• Observe real examples from other managers",
      ],
    },
    {
      title: "🛠️ Manager Toolkit",
      notes: [
        "• Recommendations for new tools/apps that can help streamline your day to day",
        "• Stay up to date on tech changes",
      ],
    },
    {
      title: "🛵 Movers and Shakers",
      notes: [
        "• Be informed on which leaders are joining/leaving different companies",
        "• Understand why the changes are happening",
      ],
    },
    {
      title: "💡 Light the way!",
      notes: [
        "• Explore ideas that can transform the way you manage your team",
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the currentIndex and loop back to 0 when it reaches the end
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 5000); // 5000 milliseconds (5 seconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    setFade(true);
    setTimeout(() => {
      setFade(false);
    }, 10); // Adjust the delay as needed
  }, [currentIndex]);

  const section_pic = (url) => {
    if (fade) return null;
    return (
      <div
        className={`slider-img`}
        style={{
          backgroundImage: `url(${url})`,
        }}
      />
    );
  };

  return (
    <div className="slider-box">
      <p className="latest">
        Check out our{" "}
        <Link to="/latest">
          <b style={{ color: "#6082d4" }}>latest newsletter!</b>
        </Link>
      </p>
      <div className="slider-container">
        {section_pic(imageUrls[currentIndex])}
        <div className="slider-content">
          <p className={`title`}>{content[currentIndex].title}</p>
          <div className="info">
            <div className={`section-content`}>
              {content[currentIndex].notes.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className="lines">
              <div id="lineh1"></div>
              <div id="lineh2"></div>
              <div id="lineh3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
