import React, { useState, useEffect } from "react";
import "../assets/styling/misc.css";
import NotFound from "../appwide/NotFound";
import { useLocation } from "react-router-dom";
import { useCheckTokenMutation } from "../slices/usersApiSlice";

const Unsub = () => {
  const [valid, setValid] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [email, setEmail] = useState("");
  const [show_form, setShowForm] = useState(false);
  const [show_btn, setShowBtn] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsletter = searchParams.get("nl");
  const account = searchParams.get("acn");
  const token = searchParams.get("utk");
  const [check, { isLoading }] = useCheckTokenMutation();

  useEffect(() => {
    checkUser();
  }, []);

  const unsubUser = async () => {
    try {
      const res = await check({
        token,
        newsletter,
        account,
      }).unwrap();
    } catch (error) {
      console.log("error");
    }
  };
  const checkUser = async () => {
    try {
      const res = await check({
        token,
        account,
      }).unwrap();

      if (res.valid) {
        setValid(true);
        setEmail(res.email);
      }
      setResolved(true);
    } catch (error) {
      console.log("error");
      setValid(false);
      setResolved(true);
    }
  };

  const clickedBtn = () => {
    setShowForm(true);
    setShowBtn(false);
    unsubUser();
  };
  const unsub_feedback = (
    <div>
      <p>
        You are now unsubscribed. If you have a minute, please tell us how we
        could have improved your experience.
      </p>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdHCJlMHpNwQHfCbENVhxdZqHlEAMLwN_hxppC30wx-jmqPuA/viewform?embedded=true"
        className="google-form"
        height="419"
      >
        Loading…
      </iframe>
    </div>
  );
  const unsub_btn = (
    <div>
      <p style={{ marginBottom: "1px" }}>
        You can unsubscribe <b>{email}</b> with the button below
      </p>
      <div className="unsub-btn" onClick={() => clickedBtn()}>
        Confirm Unsubscribe
      </div>
    </div>
  );
  const data = (
    <>
      <div style={{ marginTop: "2em" }} className="feedback-top">
        <div className="feedback-thanks">
          <p style={{ fontFamily: "Anton", fontSize: "2em" }}>
            😔 Sad to see you go
          </p>

          {show_btn ? unsub_btn : null}
          {show_form ? unsub_feedback : null}
        </div>
      </div>
    </>
  );

  if (!resolved) return null;
  return (
    <div>
      {valid ? data : <NotFound />}
      <div style={{ height: "5em" }} />
    </div>
  );
};

export default Unsub;
