import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { usePostPaymentMutation } from "../slices/usersApiSlice";
import { useDispatch, useSelector } from "react-redux";

import "../assets/styling/register.css";

import { useLocation } from "react-router-dom";

import React from "react";

const AfterPaymentScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const session = searchParams.get("session");
  const [updatePaymentProfile] = usePostPaymentMutation();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  const updateUser = async () => {
    try {
      const res = await updatePaymentProfile({
        _id: userInfo._id,
        session,
      }).unwrap();
    } catch (err) {
      console.log("err");
    }
  };
  useEffect(() => {
    updateUser();
  }, [location]);

  return (
    <div className="reg-gradient">
      <Container>
        <Row className="justify-content-md-center mt-5">
          <Col
            style={{ textAlign: "center" }}
            xs={12}
            md={9}
            lg={7}
            className="card p-5 reg-form"
          >
            <div className="reg-header">
              <AutoAwesomeIcon />
              You're Ready to Go
            </div>
            <p style={{ color: "grey", fontSize: "0.87rem" }}>
              Look out for a personalized action plan in your next newsletter.
            </p>
            <img
              style={{ width: "70%", margin: "auto" }}
              src="/images/FLHCircle.jpeg"
            />
            <Link
              to="/profile"
              className="btn btn-primary mt-3 reg-next"
              role="button"
            >
              View Profile
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AfterPaymentScreen;
