import React from "react";
import Loader from "../components/Loader";
import "../assets/styling/about.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about-section">
      <div className="pic-cover"></div>
      <div>
        <img className="pic" src="images/aboutusoffice.jpg" />
        <div className="story">
          <p className="about-title">Hey there lovely folks!</p>
          <p>
            I'm Mr. Brief! I created this newsletter because being a good
            manager is challenging work, even without constant side-eyes and
            critiques. With a decade+ of experience leading teams in the
            corporate world, I've learned the value of having relevant info
            shared at the right time. Hopefully, The Boss Brief helps you out a
            bit!
          </p>
        </div>
      </div>
      <hr />
      <div className="a-intro">
        <p style={{ marginTop: "1em" }}>
          If you haven't already joined the party then make sure to
          <Link className="signup" to="/sublanding">
            <strong> get the free Newsletter!</strong>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default About;
