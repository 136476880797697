import React from "react";
import loader from "../assets/lottie/signup.json";
import Lottie from "lottie-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import "../assets/styling/subbed.css";

const SubbedScreen = () => {
  return (
    <div>
      <div className="sub-cover"></div>
      <div>
        <div className="sub-story">
          <p className="sub-title">Welcome to the crew!</p>
          <p>
            Our regular brief <b>(Mondays)</b> will now begin arriving in your
            inbox!
          </p>
          {/* <p>Check your email to see if our first message came through.</p> */}
        </div>
        <Lottie animationData={loader} loop={true} className="sub-loader" />
      </div>
      <div className="spacer" />
      <div className="subbed-top">
        <div>
          <h3>Here are some important details:</h3>
        </div>
        <div>
          <span>
            <AccessTimeIcon />
          </span>
          <p>
            You can expect your brief at <b>around Noon EST </b>(give or take a
            few minutes)
          </p>
        </div>
        <div>
          <span>
            <ContactMailIcon />
          </span>
          <p>
            To make sure our emails get delivered, be sure to add{" "}
            <b>brief@thebossbrief.com</b> to your contacts.
          </p>
        </div>
        <div>
          <span>
            <UnsubscribeIcon />
          </span>
          <p>
            If you ever get tired of us, feel free to hit the unsubscribe link
            added to each newsletter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubbedScreen;
