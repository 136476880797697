export const trimDate = (date) => {
  const timeString = new Date(date).toLocaleDateString("en-US");

  return timeString;
};

export const pathsToCheck = [
  "/leadership-guide",
  "/online-business-for-me",
  "/find-your-business-fit",
  "/",
];
export const color_scheme = {
  main_blueish_purple: "#6082d4",
  lighter_version: "#7c9ce6",
  footer_purple: "#606e9f",
  quiz_orange: "#d49960",
  green: "#3ebe67",
  blue_gradient:
    "linear-gradient(128deg,rgb(148, 175, 242),rgb(73 115 205),rgb(142 200 217))",
};
export const setPreloadedImage = (url) => {
  const img = new Image();
  img.src = url;
  // console.log("img loaded");
};
