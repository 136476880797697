import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import { getTop3Phrases } from "../../utils/ctaMap";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { logClick } from "../../utils/clicktracker";

const AutomateSection = ({
  handleCTAScroll,
  num_map,
  version,
  campaign,
  business,
}) => {
  const handleCTA = async () => {
    handleCTAScroll();
    await logClick(version, campaign, "automate");
  };

  const [checklist_bullets, setChecklistBullets] = useState([]);
  useEffect(() => {
    if (business) {
      const bullets = getTop3Phrases(business);
      setChecklistBullets(bullets);
    }
  }, [business]);
  return (
    <Box
      sx={{
        border: "1px solid #e8e8e8;",
        borderRadius: "20px",
        padding: "10px 20px 50px",
        position: "relative",
        marginTop: "2em", // Pull up into the background image area
        backgroundColor: "#fff",
        textAlign: "center",
      }}
    >
      <div className="checklist-section">
        <p className="checklist-title">
          Set Your Business on{" "}
          <span style={{ color: "rgb(80 140 214)" }}>AutoPilot</span> and Free
          Up Your Time!
        </p>
        <div className="checklist-desc">
          Discover how to automate your key business operations with AI, freeing
          up your time and making growth effortless.
        </div>
        <div>
          <p style={{ margin: "0" }}>{business.full_name}</p>
          <img src={business.image} className="automate-img" />
          <p
            style={{
              letterSpacing: "-1px",
              margin: "1em auto 3px",
              fontWeight: "600",
            }}
          >
            Steps To Automate with AI
          </p>
          <div className="checklist-bar-back">
            <div className="checklist-loaded" />
          </div>
        </div>
        {checklist_bullets.map((bullet, index) => {
          return (
            <div
              key={index}
              className="checklist-item"
              style={{
                fontWeight: index === 0 ? "600" : "400",
                color: index === 0 ? "black" : "#ababab",
              }}
            >
              <span className="checklist-check">
                <CheckCircleIcon />{" "}
              </span>
              <p className="checklist-step">
                {Math.round(index + 1) + ". " + bullet}
              </p>
              {index === 0 ? (
                <span onClick={handleCTA} className="checklist-start-item">
                  Start <KeyboardArrowDownIcon />
                </span>
              ) : null}
            </div>
          );
        })}
        <div className="checklist-cover">
          <div className="cover-content">
            <div
              onClick={handleCTA}
              style={{ padding: "15px" }}
              className="get-full-btn"
            >
              Get Full Report
            </div>
            <div style={{ marginTop: "1rem", fontSize: "0.81rem" }}>
              <CurrencyExchangeIcon /> Risk-Free, 100% Money-Back Guarantee
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default AutomateSection;
