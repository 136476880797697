import { Navbar, Nav, Container, NavDropdown, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../assets/styling/header.css";

const QuizHomeHeader = ({ pathname }) => {
  return (
    <header>
      <Navbar className="tbb-nav" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                src={"https://d2oa23hr7q6t96.cloudfront.net/base/tbbheader.jpg"}
                height="40"
                alt="The Boss Brief logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          {pathname.includes("/find-your-business-fit") ? null : (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  {/* <LinkContainer to="/leadership-newsletter">
                <Nav.Link className="tbb-nav-link">
                  Leadership Newsletter
                </Nav.Link>
              </LinkContainer> */}

                  <LinkContainer
                    to={{
                      pathname: "/find-your-business-fit",
                      search: "?start=1",
                    }}
                  >
                    <Nav.Link id="quiz-nav-link">Take The Quiz</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>{" "}
            </>
          )}
        </Container>
      </Navbar>
    </header>
  );
};

export default QuizHomeHeader;
