import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPreferences, clearProfile } from "../../slices/usersApiSlice";
import Loader from "../Loader";
import axios from "axios";

import {
  account_preference,
  account_preference_list,
} from "../../utils/leaderImgs";
import "../../assets/styling/profile.css";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { logout } from "../../slices/authSlice";

const AccountInfo = ({ switchPage }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.profile);
  const [viewing, setViewing] = useState({});
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo) {
      setLoading(true);
      loadAccount();
    } else {
      console.log("no user");
    }
  }, []);

  const loadAccount = async () => {
    setLoading(true);
    try {
      //check redux first
      if (account.email) {
        console.log("had email");
        setViewing(account);
        setLoading(false);
      } else {
        //go to db
        console.log("empty");
        const res = await axios.post("/api/users/premium_user", {
          id: userInfo._id,
        });
        if (res.err || res.data.err) {
          setErr(true);
          navigate("/404");
        } else {
          setViewing(res.data);
          dispatch(setPreferences(res.data));
        }

        setLoading(false);
      }
    } catch (err) {
      console.log("err");
      console.log(err);
      if (err.message === "Request failed with status code 500") {
        dispatch(clearProfile());
        dispatch(logout());
        window.location.reload();
      }
    }
  };

  const preferenceSetup = (pref) => {
    const pref_key = viewing[pref];
    const wording = account_preference[pref].wording;
    const desc = account_preference[pref][pref_key].desc;

    if (pref === "icon") {
      return (
        <FormControl className="account-box" key={pref}>
          <div
            style={{
              backgroundImage:
                pref_key === "none"
                  ? ""
                  : `url(${account_preference[pref][pref_key].img})`,
            }}
            className="account-image"
          ></div>
          <p className="pref-label">{wording}</p>
          <Select
            value={pref_key}
            className="pref-selection"
            onChange={(e) => handlePreferenceChange(pref, e.target.value)}
          >
            {Object.keys(account_preference[pref]).map((key) => {
              if (key !== "wording") {
                return (
                  <MenuItem key={key} value={key}>
                    {key === "none" ? null : (
                      <img
                        className="pref-image-menu"
                        src={account_preference[pref][key].img}
                      />
                    )}
                    {account_preference[pref][key].sub}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>
      );
    }
    if (pref === "xp") {
      return (
        <FormControl className="account-box" key={pref}>
          <p className="pref-label">{wording}</p>

          <Select
            value={pref_key}
            className="pref-selection"
            onChange={(e) => handlePreferenceChange(pref, e.target.value)}
          >
            {Object.keys(account_preference[pref]).map((key) => {
              if (key !== "wording") {
                return (
                  <MenuItem key={key} value={key}>
                    {account_preference[pref][key].sub}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
          <p
            style={{ borderColor: account_preference[pref][pref_key].color }}
            className="pref-label-desc"
          >
            {desc}
          </p>
        </FormControl>
      );
    }
  };

  const handlePreferenceChange = async (pref, value) => {
    setViewing((prev) => ({
      ...prev,
      [pref]: value,
    }));
    // Optionally, can also dispatch an action to update the preferences in the store or backend
    dispatch(setPreferences({ ...viewing, [pref]: value }));
    const res = await axios.post("/api/users/premium_user", {
      id: userInfo._id,
      pref,
      value,
      update: true,
    });
  };

  if (loading) {
    return <Loader h={"15em"} m={"0"} w={"100%"} />;
  }

  return (
    <div>
      {err ? (
        <div>
          There was an error, please try reloading the page. If this message
          keeps popping up please contact us at{" "}
          <Link to="mailto:support@thebossbrief.com">
            support@thebossbrief.com
          </Link>
        </div>
      ) : (
        <div style={{ marginBottom: "3em" }} className="leader-prefs">
          <div>
            <p className="lead-pref-title">
              Your Account: <span>{userInfo.email}</span>
            </p>
          </div>
          {Object.keys(viewing).map((preference) => {
            if (preference === "icon") return preferenceSetup(preference);
            if (preference === "xp") return preferenceSetup(preference);
          })}
          {userInfo && userInfo.membership_active === true ? (
            <Link className="tc-side-link" to="/cancel">
              <span className="tc-side-item">Cancel Membership</span>
            </Link>
          ) : (
            <Link
              className="tc-side-link"
              onClick={() => switchPage("#membership")}
            >
              <span className="tc-side-item">Membership</span>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
