import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { setCredentials } from "../slices/authSlice";
import { Navbar, Nav, Container, NavDropdown, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  useUpdateUserMutation,
  useGetUserMembershipMutation,
  useLogoutMutation,
  clearProfile,
  setPreferences,
} from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
import LeaderPrefs from "../components/Profile/LeaderPrefs";
import Sidebar from "../components/Profile/Sidebar";
import React from "react";
import "../assets/styling/profile.css";
import MobileBar from "../components/Profile/MobileBar";
import AccountInfo from "../components/Profile/AccountInfo";
import UpdatePass from "../components/Profile/UpdatePass";
import Membership from "../components/Profile/Membership";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading }] = useUpdateUserMutation();
  const [getMembership] = useGetUserMembershipMutation();
  const [page, setPage] = useState("#account");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const check_param = params.get("check");
    if (check_param) {
      checkMembership(check_param);
    }
  }, []);

  useEffect(() => {
    setName(userInfo.name);
    setEmail(userInfo.email);
  }, [userInfo.setName, userInfo.setEmail]);

  const checkMembership = async (value) => {
    try {
      console.log("checking membership");
      const res = await getMembership({ user: userInfo._id, value }).unwrap();

      dispatch(setPreferences({ ...res }));
    } catch (err) {
      console.log("err");
    }
  };
  const switchPage = (page) => {
    console.log(page);
    if (page === "#logout") {
      logoutHandler();
    }
    setPage(page);
  };

  const [logoutApiCall] = useLogoutMutation();
  const logoutHandler = async () => {
    try {
      console.log("logging out");
      await logoutApiCall().unwrap();
      console.log("got to dispatch");
      dispatch(clearProfile());
      dispatch(logout());
      //   window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div className="top-bar">
        <MobileBar switchPage={switchPage} />
      </div>
      <Sidebar switchPage={switchPage} />
      <div className="account-content">
        {page === "#account" ? <AccountInfo switchPage={switchPage} /> : null}
        {page === "#traits" ? <LeaderPrefs show_type={"full"} /> : null}
        {page === "#password" ? <UpdatePass /> : null}
        {page === "#membership" ? <Membership /> : null}
        {page === "#logout" ? <></> : null}
      </div>
    </div>
  );
};

export default ProfileScreen;
