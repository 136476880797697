import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { judgePasswordStrength } from "../utils/passCheck";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import axios from "axios";

import Loader from "../components/Loader";

import React from "react";

const ResetPassScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pass_strength, setPassStrength] = useState("");
  const [strength_bar, setBar] = useState({ width: "", color: "" });
  const [issue, setIssue] = useState("");
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo && ["premium", "access"].includes(userInfo.type)) {
      navigate("/profile");
    }
  }, [navigate, userInfo]);

  const checkPass = (pass) => {
    setPassword(pass);
    const strength = judgePasswordStrength(pass);
    setPassStrength(strength.label);
    setBar(strength.percentage);
  };
  const checkConfirmPass = (pass) => {
    setConfirmPassword(pass);
    if (password !== pass) {
      setIssue("Your passwords don't match");
    } else {
      setIssue("");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/users/forgot-password", {
        email,
      });
      if (res && res.data.problem) {
        setIssue(res.data.problem);
      } else if (res) {
        setIssue("");
        setStep(2);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (/^\d*$/.test(otp)) {
      setIssue("");

      try {
        const res = await axios.post("/api/users/verify-otp", { email, otp });
        if (res && res.data.problem) {
          setIssue(res.data.problem);
        } else if (res) {
          setIssue("");
          setStep(3);
        }
      } catch (error) {
        console.log("error");
      }
    } else {
      console.log("otp err");
      setIssue("Code must be numbers only");
    }
  };

  const handlePassSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("pass dont match");
      setIssue("Your passwords don't match");
    } else {
      try {
        setLoading(true);
        const res = await axios.post("/api/users/reset-password", {
          email,
          otp,
          newPassword: password,
        });

        if (res && res.problem) {
          setIssue("There was a problem: \n" + res.problem);
        } else {
          console.log("successfully changed");
          setIssue("");
          setStep(4);
        }
        setLoading(false);
      } catch (err) {
        console.log("err");
        setIssue(
          "There was an error \n please contact us at team@thebossbrief.com for help."
        );
      }
    }
  };

  if (loading) return <Loader h={"12em"} m={"0"} w={"100%"} />;
  return (
    <div>
      <FormContainer>
        <p className="login-reg-header">Password Reset</p>
        <p
          style={{
            color: "grey",
            fontSize: "0.87rem",
            margin: "0 0 1em",
            paddingBottom: "5px",
          }}
        ></p>
        {step === 1 && (
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-2" controlId="email">
              <Form.Label className="reg-label">Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={email}
                className="reg-input"
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
            <Button
              disabled={email === "" ? true : false}
              type="submit"
              variant="primary"
              className="mt-3 reg-submit"
            >
              Get Reset Code
            </Button>
          </Form>
        )}
        {step === 2 && (
          <Form onSubmit={handleOtpSubmit}>
            <p
              style={{
                background: "whitesmoke",
                textAlign: "center",
                padding: "5px 10px",
              }}
            >
              Check your email for the reset code we sent to you
            </p>
            <Form.Group className="my-2" controlId="otpcode">
              <Form.Control
                type="text"
                placeholder="Enter Code"
                value={otp}
                className="reg-input"
                onChange={(e) => setOtp(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
            <Button
              disabled={otp === "" ? true : false}
              type="submit"
              variant="primary"
              className="mt-3 reg-submit"
            >
              Submit Code
            </Button>
          </Form>
        )}
        {step === 3 && (
          <Form onSubmit={handlePassSubmit}>
            <p
              style={{
                background: "#bff8c1",
                textAlign: "center",
                padding: "5px 10px",
              }}
            >
              Enter your new password
            </p>

            <Form.Group className="my-1" controlId="password">
              <Form.Label className="reg-label">New Password</Form.Label>
              <Form.Control
                type="password"
                className="reg-input"
                value={password}
                onChange={(e) => checkPass(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {pass_strength !== "" ? (
              <div className="pass-strength">
                <span className="pass-label">{pass_strength}</span>
                <div
                  style={{
                    backgroundColor: "#ebebeb",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    style={{
                      width: strength_bar.width,
                      backgroundColor: strength_bar.color,
                    }}
                    className="pass-strength-bar"
                  ></span>
                </div>
              </div>
            ) : null}
            <Form.Group className="my-1" controlId="confirmPassword">
              <Form.Label className="reg-label">
                Confirm New Password
              </Form.Label>
              <Form.Control
                type="password"
                className="reg-input"
                value={confirmPassword}
                onChange={(e) => checkConfirmPass(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
            <Button
              disabled={
                password === "" || confirmPassword === "" ? true : false
              }
              type="submit"
              variant="primary"
              className="mt-3 reg-submit"
            >
              Update Password
            </Button>
          </Form>
        )}
        {step === 4 && (
          <div>
            <p className="pass-reset">
              <DoneOutlineIcon /> Your Password has been updated. You can now
              login <Link to={"/login"}>here.</Link>
            </p>
          </div>
        )}
      </FormContainer>
    </div>
  );
};

export default ResetPassScreen;
