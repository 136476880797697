import React, { useState, useEffect, useRef } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SavingsTwoToneIcon from "@mui/icons-material/SavingsTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import LaptopTwoToneIcon from "@mui/icons-material/LaptopTwoTone";
import PaletteTwoToneIcon from "@mui/icons-material/PaletteTwoTone";
import VideoLibraryTwoToneIcon from "@mui/icons-material/VideoLibraryTwoTone";
import RecordVoiceOverTwoToneIcon from "@mui/icons-material/RecordVoiceOverTwoTone";
import HeadsetMicTwoToneIcon from "@mui/icons-material/HeadsetMicTwoTone";
import VideocamTwoToneIcon from "@mui/icons-material/VideocamTwoTone";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import { MoneyGroup } from "./MoneyGroup";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import { logClick } from "../../utils/clicktracker";
import AutomateSection from "./AutomateSection";
import StrengthSection from "./StrengthSection";
import { getTop3Phrases } from "../../utils/ctaMap";
import "../../assets/styling/animations.css";

// Custom hook for getting window size
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const BarGraph = ({
  map,
  colors,
  datasets,
  bars,
  version,
  campaign,
  num_map,
  handleCTAScroll,
}) => {
  const chartSetting = {
    bottomAxis: null,
    leftAxis: null,
    height: 30,
    slotProps: {
      legend: {
        hidden: true,
      },
    },
  };
  const { width } = useWindowSize();

  const noticeLabel = (og) => {
    const color = og.toLowerCase();
    if (color === "green")
      return <CheckCircleIcon sx={{ fontSize: 22, color: "#4ec178" }} />;
    if (color === "yellow")
      return <WarningIcon sx={{ fontSize: 22, color: "#ffbb3d" }} />;
    if (color === "red")
      return <WarningIcon sx={{ fontSize: 22, color: "#c1524e" }} />;
  };
  const skillLevel = (level) => {
    if (level <= 3) return "Low";
    if (level <= 6) return "Medium";
    if (level <= 10) return "High";
  };

  const datasetList = [
    {
      title: "Top Picks",
      data: datasets.topResults,
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/b998f07f63bd94a23e9de737d528e10742be349877e18a0c32ef3a8580676750",
      class: "top-list-back",
    },
    {
      title: "Most Enjoyable",
      data: datasets.mostFun,
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/75dd768f56af2c0ee742049f07659f3011c4c23b4f1db465da1a784ab5d55d1a",
      class: "fun-list-back",
    },
    {
      title: "Skill Set",
      data: datasets.bestForSkillset,
      image:
        "https://d2oa23hr7q6t96.cloudfront.net/67174052360f3725c4cda832/Dis/cd0fc4528a665b87278730c3c7d7367173814f793442378b3ea03f2cccaaa11e",
      class: "skill-list-back",
    },
  ];
  const handleCTA = async () => {
    handleCTAScroll();
    await logClick(version, campaign, "traits");
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(0);
  const infoBoxRef = useRef(null);

  const explainer = {
    0: "These businesses align best with your overall preferences, goals, and personality!",
    1: "These options are likely to bring you joy and excitement once up and running. Great if you're looking to combine the business with an existing passion!",
    2: "These business ideas leverage your skills and strengths, making it easier to hit the ground running, but they may not be ones you enjoy the most.",
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedBusinessIndex(0);
  };

  const handleBarClick = (index) => {
    // if (selectedBusinessIndex !== index) {
    setSelectedBusinessIndex(index);
    infoBoxRef.current.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const handlePrev = () => {
    setSelectedBusinessIndex((prevIndex) =>
      prevIndex === 0 ? currentDataset.length - 1 : prevIndex - 1
    );
    infoBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleNext = () => {
    setSelectedBusinessIndex((prevIndex) =>
      prevIndex === currentDataset.length - 1 ? 0 : prevIndex + 1
    );
    infoBoxRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const currentDataset = datasetList[tabIndex].data;
  const selectedBusiness = currentDataset[selectedBusinessIndex];

  //not using
  const maxVotes = Math.max(...currentDataset.map((item) => item.score)) / 0.95;
  const container_width = (votes) => {
    let base_container = width < 600 ? width - 20 : 600;
    const c_w = Math.round(base_container * (votes / maxVotes));
    return c_w;
  };
  // Calculate the maximum score for the current dataset
  const maxScore = Math.max(...currentDataset.map((item) => item.score)) / 0.93;

  const chartWidth = width < 600 ? width - 20 : 600;
  return (
    <div>
      {/* Tabs for datasets */}
      <div style={{ background: "#e2e4eab3" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          style={{ marginBottom: "0" }}
        >
          {datasetList.map((dataset, index) => (
            <Tab key={index} label={dataset.title} />
          ))}
        </Tabs>
        <p className="tab-explain">{explainer[tabIndex]}</p>
      </div>
      {/* Label Above the Graph */}
      <div className="select-biz-tag">
        <p>Select a business to see</p>
        <p>why it fits you</p>
        <p>
          <ArrowDownwardIcon sx={{ color: "#6db9f3", fontSize: 30 }} />
        </p>
      </div>
      {/* Bar Chart */}
      {currentDataset.map((item, index) => (
        <div
          key={index}
          onClick={() => handleBarClick(index)}
          style={{
            cursor: "pointer",
          }}
        >
          <p className="bar-title">
            <span className="bt-a">{item.full_name}</span>
          </p>
          <div
            className={selectedBusinessIndex === index ? "moving-border" : ""}
            style={{
              marginBottom: "10px",
              // border:
              //   selectedBusinessIndex === index ? "4px solid #202020" : "none",
            }}
          >
            <div
              style={{
                background: "#e8e8e8",
              }}
            >
              <BarChart
                dataset={[item]}
                xAxis={[
                  {
                    dataKey: "score",
                    min: 0,
                    max: maxScore,
                  },
                ]}
                yAxis={[
                  {
                    dataKey: "full_name",
                    scaleType: "band",
                  },
                ]}
                series={[
                  {
                    dataKey: "score",
                    label: item.full_name,
                    color: colors[tabIndex + 1][index],
                  },
                ]}
                layout="horizontal"
                {...chartSetting}
                width={chartWidth}
                axisHighlight={{ x: "none", y: "none" }}
                margin={{ left: 0, top: -4, bottom: -4, right: 0 }}
              />
            </div>
          </div>
        </div>
      ))}

      {/* Information Box */}
      <div style={{ paddingTop: "2em" }} ref={infoBoxRef}>
        {/* Full-width Static Background Image */}
        <div
          className={datasetList[tabIndex].class}
          style={{
            width: "100%",
            height: "200px",
            backgroundImage: `url(${datasetList[tabIndex].image})`,
            backgroundSize: "cover",
            borderRadius: "10px",
            opacity: "0.9",
          }}
        />
        <Box
          sx={{
            border: "1px solid #e8e8e8;",
            borderRadius: "20px",
            padding: "10px 20px 50px",
            position: "relative",
            marginTop: "-100px", // Pull up into the background image area
            backgroundColor: "#fff",
          }}
        >
          {/* Navigation Buttons */}
          <IconButton
            onClick={handlePrev}
            sx={{ position: "absolute", top: "10px", left: "10px" }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>

          {/* Rank Display */}
          <div className="biz-rank-title">
            <p>
              {datasetList[tabIndex].title} #{selectedBusinessIndex + 1}
            </p>
          </div>

          {/* Business Image and Title */}
          <div className="biz-highlight">
            <img
              // src={selectedBusiness.image}
              src={selectedBusiness.image}
              alt={selectedBusiness.full_name}
            />

            <div className="biz-highlight-desc">
              <p className="biz-name">{selectedBusiness.full_name}</p>
              <p className="biz-desc">{selectedBusiness.desc}</p>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "1em",
                letterSpacing: "-1px",
              }}
            >
              Brief Market Research <TrendingUpIcon sx={{ fontSize: 20 }} />
            </div>
            <ul className="biz-market">
              {selectedBusiness.market_size.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Details */}
          <div style={{ marginTop: "20px" }}>
            {/* Foundational Section */}
            <div className="highlight-section">
              <p style={{ marginTop: "0" }} className="h-title">
                Getting Started
              </p>
              <div className="bar-box">
                <span>
                  <SavingsTwoToneIcon sx={{ fontSize: 30, color: "#727272" }} />{" "}
                  Setup Costs
                </span>

                <div className="biz-bar-back">
                  <div
                    style={{
                      width: `${selectedBusiness.capital * 10}%`,
                      height: "100%",
                      backgroundColor: "#727272",
                    }}
                  />
                </div>
                <div className="bar-info">
                  {" "}
                  {noticeLabel(
                    map.alignment[selectedBusiness.business].capital.alignment
                  )}{" "}
                  {map.alignment[selectedBusiness.business].capital.text}
                </div>
              </div>
              <div className="bar-box">
                <span>
                  <AccessTimeTwoToneIcon
                    sx={{ fontSize: 30, color: "#727272" }}
                  />{" "}
                  Launch Timeline
                </span>
                <div className="biz-bar-back">
                  <div
                    style={{
                      width: `${selectedBusiness.time * 10}%`,
                      height: "100%",
                      backgroundColor: "#727272",
                    }}
                  />
                </div>
                <div className="bar-info">
                  {" "}
                  {noticeLabel(
                    map.alignment[selectedBusiness.business].time.alignment
                  )}{" "}
                  {map.alignment[selectedBusiness.business].time.text}
                </div>
              </div>
              <div style={{ marginBottom: "0" }} className="bar-box">
                <span>
                  <AssessmentTwoToneIcon
                    sx={{ fontSize: 30, color: "#727272" }}
                  />{" "}
                  Risk Involved
                </span>
                <div className="biz-bar-back">
                  <div
                    style={{
                      width: `${selectedBusiness.risk * 10}%`,
                      height: "100%",
                      backgroundColor: "#727272",
                    }}
                  />
                </div>
                <div className="bar-info">
                  {" "}
                  {noticeLabel(
                    map.alignment[selectedBusiness.business].risk.alignment
                  )}{" "}
                  {map.alignment[selectedBusiness.business].risk.text}
                </div>
              </div>
            </div>
            {/* Earning Section */}
            <div className="highlight-section">
              <p className="h-title">
                Earnings Potential <span className="h-title-split">|</span>
                <span>{selectedBusiness.full_name}</span>
              </p>
              <div className="earnings-section">
                <div
                  style={{ border: "6px dashed rgb(79 194 120)" }}
                  className="biz-earning"
                >
                  <div style={{ minWidth: "140px" }} className="op-title">
                    Potential Income
                  </div>

                  <span>
                    <MoneyGroup amount={selectedBusiness.income_amount} />
                  </span>
                </div>
                <div className="biz-earning">
                  <div className="op-title">
                    {" "}
                    <SpeedTwoToneIcon
                      sx={{ fontSize: 30, color: "#727272" }}
                    />{" "}
                    Revenue Speed
                  </div>
                  <span
                    style={{ marginTop: "8px", display: "block" }}
                    className="span-info"
                  >
                    {" "}
                    {noticeLabel(
                      map.alignment[selectedBusiness.business].income_speed
                        .alignment
                    )}{" "}
                    {map.alignment[selectedBusiness.business].income_speed.text}
                  </span>
                </div>
              </div>
            </div>
            {/* Operational Section */}
            <div className="highlight-section">
              <p className="h-title">
                Day-to-Day Operations <span className="h-title-split">|</span>
                <span>{selectedBusiness.full_name}</span>
              </p>
              <div>
                <div
                  style={{
                    display: "block",
                    textAlign: "left",
                    // justifyContent: "space-between",
                  }}
                >
                  <div className="biz-operational">
                    <div className="op-title">
                      <LaptopTwoToneIcon
                        sx={{ fontSize: 30, color: "#727272" }}
                      />{" "}
                      Tech
                    </div>
                    <div className="op-level">
                      {skillLevel(selectedBusiness.tech)}
                    </div>
                    <div>
                      <span className="span-info">
                        {" "}
                        {noticeLabel(
                          map.alignment[selectedBusiness.business].tech
                            .alignment
                        )}{" "}
                        {map.alignment[selectedBusiness.business].tech.text}
                      </span>
                    </div>
                  </div>
                  <div className="biz-operational">
                    <div className="op-title">
                      <PaletteTwoToneIcon
                        sx={{ fontSize: 30, color: "#727272" }}
                      />{" "}
                      Design
                    </div>
                    <div className="op-level">
                      {skillLevel(selectedBusiness.design)}
                    </div>
                    <span className="span-info">
                      {" "}
                      {noticeLabel(
                        map.alignment[selectedBusiness.business].design
                          .alignment
                      )}{" "}
                      {map.alignment[selectedBusiness.business].design.text}
                    </span>
                  </div>
                  <div className="biz-operational">
                    <div className="op-title">
                      <VideoLibraryTwoToneIcon
                        sx={{ fontSize: 30, color: "#727272" }}
                      />{" "}
                      Content Creation
                    </div>
                    <div className="op-level">
                      {skillLevel(selectedBusiness.content)}
                    </div>
                    <span className="span-info">
                      {" "}
                      {noticeLabel(
                        map.alignment[selectedBusiness.business].content
                          .alignment
                      )}{" "}
                      {map.alignment[selectedBusiness.business].content.text}
                    </span>
                  </div>
                  <div className="biz-operational">
                    <div className="op-title">
                      <RecordVoiceOverTwoToneIcon
                        sx={{ fontSize: 30, color: "#727272" }}
                      />{" "}
                      Personal Brand
                    </div>
                    <div className="op-level">
                      {skillLevel(selectedBusiness.content)}
                    </div>
                    <span className="span-info">
                      {" "}
                      {noticeLabel(
                        map.alignment[selectedBusiness.business].brand.alignment
                      )}{" "}
                      {map.alignment[selectedBusiness.business].brand.text}
                    </span>
                  </div>
                  <div className="biz-operational">
                    <div className="op-title">
                      <HeadsetMicTwoToneIcon
                        sx={{ fontSize: 30, color: "#727272" }}
                      />{" "}
                      Support
                    </div>
                    <div className="op-level">
                      {skillLevel(selectedBusiness.content)}
                    </div>
                    <span className="span-info">
                      {" "}
                      {noticeLabel(
                        map.alignment[selectedBusiness.business].support
                          .alignment
                      )}{" "}
                      {map.alignment[selectedBusiness.business].support.text}
                    </span>
                  </div>
                  <div className="biz-operational">
                    <div className="full-op-icons">
                      <span>
                        {" "}
                        <SchoolTwoToneIcon
                          sx={{ fontSize: 20, color: "#fff" }}
                        />
                      </span>
                      <span style={{ marginLeft: "-5px" }}>
                        {" "}
                        <PersonAddAltTwoToneIcon
                          sx={{ fontSize: 20, color: "#fff" }}
                        />
                      </span>
                      <span style={{ marginLeft: "-7px" }}>
                        {" "}
                        <VideocamTwoToneIcon
                          sx={{ fontSize: 20, color: "#fff" }}
                        />
                      </span>
                      <span style={{ marginLeft: "-7px" }}>
                        {" "}
                        <GroupsTwoToneIcon
                          sx={{ fontSize: 20, color: "#fff" }}
                        />
                      </span>
                    </div>
                    <div className="get-full-title">Get the Full Picture</div>
                    {/* View your report to see all the ways you match this business! */}
                    <div className="get-full-desc">
                      See all the details of how you match with this business!
                    </div>

                    <div onClick={handleCTA} className="get-full-btn">
                      Get Full Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Navigation Buttons */}
          <IconButton
            onClick={handlePrev}
            sx={{ position: "absolute", bottom: "10px", left: "10px" }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Box>
      </div>

      {version === "auto" ? (
        <AutomateSection
          num_map={num_map}
          version={version}
          campaign={campaign}
          handleCTAScroll={handleCTAScroll}
          results={datasets}
          business={selectedBusiness}
        />
      ) : (
        <StrengthSection
          num_map={num_map}
          version={version}
          campaign={campaign}
          handleCTAScroll={handleCTAScroll}
        />
      )}
    </div>
  );
};

export default BarGraph;
