// src/components/Question.js
import React, { useState, useEffect } from "react";
import {
  Slider,
  FormControl,
  FormLabel,
  Button,
  Box,
  Fade,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import DropdownQuestion from "./DropdownQuestion";
import ProgressBar from "./ProgressBar";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { mbti_list } from "../../utils/mbti_list";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333",
    },
    success: {
      main: "#5381f1",
    },
  },
});
const valuetext = (value) => {
  return `${value}`;
};

const Question = ({
  question,
  answers,
  onNext,
  onPrevious,
  handleSelection,
  currentAnswer,
  currentQuestion,
  lastQ,
  current,
  total,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    currentAnswer >= 0 ? currentAnswer : 0
  );
  const [dropDown, setDropdown] = useState(false);
  const [selectedMBTI, setMBTI] = useState("");
  const [image, setImage] = useState(question.images[selectedOption]);
  const [sub, setSub] = useState(question.subtext[selectedOption]);

  useEffect(() => {
    setSelectedOption(currentAnswer >= 0 ? currentAnswer : 0);
  }, [currentAnswer]);
  useEffect(() => {
    const selectedAnswer = answers[question.text];
    setSelectedOption(
      selectedAnswer !== undefined && !mbti_list.includes(selectedAnswer)
        ? question.options.indexOf(selectedAnswer)
        : 0
    );
  }, [question]);

  useEffect(() => {
    setImage(question.images[selectedOption]);
    setSub(question.subtext[selectedOption]);
  }, [selectedOption, question.images, question.subtext]);

  const marks = question.options.map((option, index) => ({
    value: index,
    label: option,
  }));

  const handleMBTI = (choice) => {
    setMBTI(choice);
    handleSelection(choice, currentQuestion);
  };

  const handleChoice = (newValue, move = false, next_spot = true) => {
    if (newValue >= 0 && newValue < question.options.length) {
      setSelectedOption(newValue);
      let answer = question.options[newValue];

      if (lastQ && selectedMBTI !== "") {
        answer = selectedMBTI;
      }
      handleSelection(answer, currentQuestion);
      if (move) {
        if (next_spot) {
          onNext();
        } else {
          onPrevious();
        }
      }
    }
  };
  const useSimple = () => {
    setDropdown(false);
    setMBTI("");
    setSelectedOption(0); // Reset to first option when switching to simple version
    handleSelection(question.options[0], currentQuestion); // Update answer with the first option
    setImage(question.images[0]);
    setSub(question.subtext[0]);
  };
  const mbti = () => {
    return (
      <div className="mbti">
        If you prefer to use Myers Briggs{" "}
        <span
          onClick={() => {
            setDropdown(true);
          }}
        >
          click here
        </span>
      </div>
    );
  };
  return (
    <Fade in={true}>
      <div
        className="survey-box"
        style={{ backgroundImage: "url(" + image + ")" }}
        mt={5}
      >
        <div className="question-box">
          <ProgressBar current={current} total={total} />

          <Box className="lower-box">
            <div className="customize-question">{question.text}</div>
            {dropDown && lastQ ? (
              <div className="mbti-autocomplete">
                <Stack spacing={2} style={{ width: "100%" }}>
                  <Autocomplete
                    freeSolo
                    options={mbti_list.map((option) => option)}
                    value={selectedMBTI}
                    onSelect={(e) => handleMBTI(e.target.value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="input"
                        label="Myers Briggs"
                        variant="filled"
                      />
                    )}
                  />
                </Stack>
                <p
                  className="simple-version"
                  onClick={() => {
                    useSimple();
                  }}
                >
                  Use the simple version
                </p>
              </div>
            ) : (
              <>
                <div
                  className="customize-subtext"
                  style={{ background: question.color.b + "3b" }}
                >
                  <b>{`${question.options[selectedOption]}`}: </b>
                  {`${sub}`}
                </div>
                <Slider
                  aria-label=""
                  value={selectedOption}
                  getAriaValueText={valuetext}
                  step={1}
                  style={{
                    height: "15px",
                    color: question.color.a,
                    width: "70%",
                  }}
                  marks={marks}
                  max={question.options.length - 1}
                  onChange={(event, newValue) => handleChoice(newValue)}
                />
                {lastQ ? mbti() : null}
              </>
            )}
          </Box>
        </div>

        <ThemeProvider theme={theme}>
          {" "}
          <Box
            className="bottom-box"
            display="flex"
            justifyContent="space-between"
          >
            <Button
              color="primary"
              onClick={() => handleChoice(selectedOption, true, false)}
              disabled={currentQuestion === 0}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            >
              Previous
            </Button>
            <Button
              color={lastQ ? "success" : "primary"}
              onClick={() => handleChoice(selectedOption, true, true)}
              endIcon={
                lastQ ? (
                  <CheckCircleRoundedIcon />
                ) : (
                  <ArrowForwardIosRoundedIcon />
                )
              }
              variant={lastQ ? "outlined" : "text"}
            >
              {lastQ ? "Submit" : "Next"}
            </Button>
          </Box>
        </ThemeProvider>
      </div>
    </Fade>
  );
};

export default Question;
