import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { logClick } from "../../utils/clicktracker";
import { getStrengthsWording } from "../../utils/ctaMap";

const StrengthSection = ({ handleCTAScroll, version, campaign, num_map }) => {
  const [checklist_bullets, setChecklist] = useState(
    getStrengthsWording(num_map)
  );
  const handleCTA = async () => {
    handleCTAScroll();
    await logClick(version, campaign, "strengths");
  };

  return (
    <Box
      sx={{
        border: "1px solid #e8e8e8;",
        borderRadius: "20px",
        padding: "10px 20px 50px",
        position: "relative",
        marginTop: "2em", // Pull up into the background image area
        backgroundColor: "#fff",
        textAlign: "center",
      }}
    >
      <div className="checklist-section">
        <p className="checklist-title">
          Transform Weak Areas Into{" "}
          <span style={{ color: "rgb(80 140 214)", fontWeight: "bold" }}>
            Powerful
          </span>{" "}
          Assets
        </p>
        <div style={{ marginBottom: "2em" }} className="checklist-desc">
          Get guidance on how to leverage your strengths and turn weaknesses
          into opportunities for growth.
        </div>
        {/* <div>
        {" "}
        <div className="checklist-bar-back">
          <div className="checklist-loaded" />
        </div>
      </div> */}
        {checklist_bullets.map((bullet, index) => {
          return (
            <div
              key={index}
              className="checklist-item"
              style={{
                fontWeight: index === 0 ? "600" : "400",
                color: index === 0 ? "black" : "#ababab",
              }}
            >
              <span>
                <CheckCircleIcon />{" "}
              </span>
              {Math.round(index + 1) + ". " + bullet}
              {/* {index === 0 ? (
              <span className="checklist-start-item">
                Start <KeyboardArrowDownIcon />
              </span>
            ) : null} */}
            </div>
          );
        })}
        <div className="checklist-cover">
          <div className="cover-content">
            <div
              onClick={handleCTA}
              style={{ padding: "15px" }}
              className="get-full-btn"
            >
              Get Full Report
            </div>
            <div style={{ marginTop: "15px", fontSize: "13px" }}>
              <CurrencyExchangeIcon /> Risk-Free, 100% Money-Back Guarantee
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default StrengthSection;
