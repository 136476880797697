import React from "react";
import { Button } from "react-bootstrap";

const TrialSplashScreen = ({ startRegister }) => {
  const goToRegister = () => {
    //need
    console.log("ready to begin");
    startRegister();
  };
  const trial_path = [
    { step: 1, icon: "icon", title: "", context: "" },
    { step: 2, icon: "icon", title: "", context: "" },
    { step: 3, icon: "icon", title: "", context: "" },
    { step: 4, icon: "icon", title: "", context: "" },
  ];
  return (
    <div style={{ maxWidth: "800px", margin: "2em auto", textAlign: "center" }}>
      <div>
        <div className="hero-left">
          <div>
            <p className="hero-title">
              Leadership news that's <span>smart</span> & <span>fun</span>!
            </p>
            <p className="subhead">
              Get the latest in leadership news and trends with a
              <strong> free newsletter every Monday! </strong>
              Designed for time-strapped managers, we keep things informative,
              enjoyable and brief. 😉
            </p>
          </div>
        </div>
        <div className="hero-right">
          <div>
            <img className="hero-img" src="/images/FLHCircle.jpeg" />
          </div>
        </div>
      </div>

      <div>
        {trial_path.map((step, key) => (
          <div className="path-block" key={key}>
            <div className="path-icon-circle">
              circle <span className="path-line">{step.step}</span>
            </div>
            <div className="path-details">
              <p>title</p>
              <p>details of cotnext</p>
            </div>
          </div>
        ))}
      </div>
      <Button
        className="path-btn"
        onClick={() => {
          goToRegister();
        }}
      >
        Get Started
      </Button>
    </div>
  );
};

export default TrialSplashScreen;
