export const leader_preference = {
  personality: {
    wording: "Personality",
    introverted: { img: "/images/Personality1.jpeg", sub: "Introverted" },
    intj: { img: "/images/Personality1.jpeg", sub: "INTJ" },
    intp: { img: "/images/Personality1.jpeg", sub: "INTP" },
    infj: { img: "/images/Personality1.jpeg", sub: "INFJ" },
    infp: { img: "/images/Personality1.jpeg", sub: "INFP" },
    istj: { img: "/images/Personality1.jpeg", sub: "ISTJ" },
    isfj: { img: "/images/Personality1.jpeg", sub: "ISFJ" },
    istp: { img: "/images/Personality1.jpeg", sub: "ISTP" },
    isfp: { img: "/images/Personality1.jpeg", sub: "ISFP" },
    extroverted: { img: "/images/Personality2.jpeg", sub: "Extroverted" },
    entj: { img: "/images/Personality2.jpeg", sub: "ENTJ" },
    entp: { img: "/images/Personality2.jpeg", sub: "ENTP" },
    enfj: { img: "/images/Personality2.jpeg", sub: "ENFJ" },
    enfp: { img: "/images/Personality2.jpeg", sub: "ENFP" },
    estj: { img: "/images/Personality2.jpeg", sub: "ESTJ" },
    esfj: { img: "/images/Personality2.jpeg", sub: "ESFJ" },
    estp: { img: "/images/Personality2.jpeg", sub: "ESTP" },
    esfp: { img: "/images/Personality2.jpeg", sub: "ESFP" },
  },
  team: {
    wording: "Team Size",
    small: { img: "/images/Team1.jpeg", sub: "Small" },
    med: { img: "/images/Team2.jpeg", sub: "Mid Sized" },
    large: { img: "/images/Team3.jpeg", sub: "Large" },
  },
  calendar: {
    wording: "Calendar",
    normal: { img: "/images/Busy2.jpeg", sub: "Normal" },
    busy: { img: "/images/Busy1.jpeg", sub: "Busy" },
  },
  vibes: {
    wording: "Atmosphere",
    normal: { img: "/images/Toxic1.jpeg", sub: "Normal" },
    toxic: { img: "/images/Toxic2.jpeg", sub: "Toxic" },
  },

  location: {
    wording: "Work Location",
    office: { img: "/images/Location1.jpeg", sub: "In the office" },
    remote: { img: "/images/Location2.jpeg", sub: "Remote/WFH" },
    hybrid: { img: "/images/Location3.jpeg", sub: "Hybrid" },
  },
};
export const leader_preference_list = Object.keys(leader_preference);

export const account_preference = {
  icon: {
    wording: "Profile Icon",
    frank: {
      img: "https://d2oa23hr7q6t96.cloudfront.net/65fde82f7c79ff4c9bd36047/Ne/a11ffddf390d4583e2edbb0235d39455522a0333e3b990367ad808b2a7cc7d8e",
      sub: "Frank",
    },
    lisa: {
      img: "https://d2oa23hr7q6t96.cloudfront.net/65fde82f7c79ff4c9bd36047/Ne/4781288d80998abf9f6b41f396484876673ba8dded659adac0fa5a35e15f56c7",
      sub: "Lisa",
    },
    none: { img: "", sub: "None" },
  },
  xp: {
    wording: "Leadership Experience",
    aspiring: {
      img: "/images/Team1.jpeg",
      sub: "Aspiring Manager",
      desc: "Currently an individual contributor, but you're on the path to taking charge and guiding your team to success. It's about learning the ropes, understanding your team's needs, and preparing to make an impact.",
      color: "#abc0ef",
    },
    low: {
      img: "/images/Team2.jpeg",
      sub: "First-Line (Supervise)",
      desc: "As a first-line manager, you're in the trenches with your team, making sure things get done right. You're the go-to person for your group, turning plans from higher-ups into action. You're also the first to hear about any issues or concerns your team has, and you help sort them out. Think of yourself as the bridge between your team and the bigger picture.",
      color: "#5b8dff",
    },
    mid: {
      img: "/images/Team3.jpeg",
      sub: "Mid-Level (Execute)",
      desc: "In the mid-level manager role, you're the glue holding everything together. You take the big ideas from top-level managers and make them happen on the ground. Your job is to guide first-line managers, keep everyone on track, and make sure your branch or department hits its goals. You're all about turning strategy into reality and helping your team succeed.",
      color: "#6784c8",
    },
    high: {
      img: "/images/Team4.jpeg",
      sub: "Top-Level (Strategize)",
      desc: "As a top-level manager, you're at the helm of the ship, steering the company toward its long-term goals. You're focused on the big picture, setting the direction, and ensuring everything runs smoothly. It's your job to create a successful, profitable, and well-respected company. You have the ultimate responsibility but also listen to feedback from all levels to make the best decisions.",
      color: "#0a37a3",
    },
  },
};
export const account_preference_list = Object.keys(account_preference);
