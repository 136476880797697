import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import FormContainer from "../../components/FormContainer";
import Loader from "../../components/Loader";
import { judgePasswordStrength } from "../../utils/passCheck";
import { useUpdatePassMutation } from "../../slices/usersApiSlice";
import "../../assets/styling/register.css";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import React from "react";

const UpdatePass = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pass_strength, setPassStrength] = useState("");
  const [strength_bar, setBar] = useState({ width: "", color: "" });
  const [issue, setIssue] = useState("");
  const [changed, setChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updatePass] = useUpdatePassMutation();

  const checkPass = (pass) => {
    setPassword(pass);
    const strength = judgePasswordStrength(pass);
    setPassStrength(strength.label);
    setBar(strength.percentage);
  };
  const checkConfirmPass = (pass) => {
    setConfirmPassword(pass);
    if (password !== pass) {
      setIssue("Your passwords don't match");
    } else {
      setIssue("");
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("pass dont match");
      setIssue("Your passwords don't match");
    } else {
      try {
        setLoading(true);
        const res = await updatePass({
          id: userInfo._id,
          oldpassword,
          password,
        }).unwrap();

        if (res && res.problem) {
          setIssue("There was a problem: \n" + res.problem);
        } else {
          console.log("successfully changed");
          setChanged(true);
        }
        setLoading(false);
      } catch (err) {
        console.log("err");
        setIssue(
          "There was an error \n please contact us at team@thebossbrief.com for help."
        );
      }
    }
  };

  if (isLoading)
    return (
      <div className="reg-gradient">
        <div className="reg-loader">
          <Loader />
        </div>
      </div>
    );

  return (
    <div>
      <p className="lead-pref-title">Update Password</p>
      {changed ? (
        <div>
          <p className="pass-updated">
            <DoneOutlineIcon /> Your Password has been updated
          </p>
        </div>
      ) : (
        <Container>
          <Row className="justify-content-md-center mt-5">
            <Col xs={12} md={9} lg={9} className="card p-5 reg-form">
              <Form data-sparkloop-form-ignore="true" onSubmit={submitHandler}>
                <div
                  style={{ marginBottom: "1em" }}
                  className="reg-email-cover"
                >
                  <p
                    style={{
                      margin: "0",
                      fontSize: "15px",
                      display: "inline-block",
                    }}
                  >
                    Enter your new password details below
                  </p>
                </div>

                <Form.Group className="my-1" controlId="oldpassword">
                  <Form.Label className="reg-label">
                    Current Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="reg-input"
                    value={oldpassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="my-1" controlId="password">
                  <Form.Label className="reg-label">New Password</Form.Label>
                  <Form.Control
                    type="password"
                    className="reg-input"
                    value={password}
                    onChange={(e) => checkPass(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {pass_strength !== "" ? (
                  <div className="pass-strength">
                    <span className="pass-label">{pass_strength}</span>
                    <div
                      style={{
                        backgroundColor: "#ebebeb",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        style={{
                          width: strength_bar.width,
                          backgroundColor: strength_bar.color,
                        }}
                        className="pass-strength-bar"
                      ></span>
                    </div>
                  </div>
                ) : null}
                <Form.Group className="my-1" controlId="confirmPassword">
                  <Form.Label className="reg-label">
                    Confirm New Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    className="reg-input"
                    value={confirmPassword}
                    onChange={(e) => checkConfirmPass(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {issue !== "" ? <div className="reg-issue">{issue}</div> : null}
                <Button
                  disabled={
                    oldpassword === "" ||
                    password === "" ||
                    confirmPassword === ""
                      ? true
                      : false
                  }
                  type="submit"
                  variant="primary"
                  className="mt-3 reg-submit"
                >
                  Update Password
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default UpdatePass;
