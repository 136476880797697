// useSparkloopScript.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSparkloopScript = () => {
  const location = useLocation();

  useEffect(() => {
    const loadSparkloop = () => {
      console.log("loading SL");
      const script = document.createElement("script");
      script.src = "https://js.sparkloop.app/team_4b5dfea4ea57.js";
      script.setAttribute("data-sparkloop", "");
      script.async = true;
      document.head.appendChild(script);
    };

    const unloadSparkloop = () => {
      // console.log("unloading SL");
      const existingScript = document.querySelector("script[data-sparkloop]");
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };

    const sparkloop_urls = ["/leadership-newsletter", "/sublanding"];
    if (sparkloop_urls.some((path) => location.pathname === path)) {
      loadSparkloop();
    } else {
      unloadSparkloop();
    }

    // Clean up the script when the component unmounts or when the pathname changes
    return () => {
      unloadSparkloop();
    };
  }, [location.pathname]);
};

export default useSparkloopScript;
