import React from "react";
import "../assets/styling/404.css";
import { Link } from "react-router-dom";

const AppHadError = () => {
  return (
    <div className="not-here">
      <p className="top" style={{ fontSize: "6em" }}>
        Error
      </p>
      <p>
        <b>We're having some issues at the moment...</b>
      </p>
      <img src="https://d2oa23hr7q6t96.cloudfront.net/base/error_office.jpg" />
      <p style={{ marginTop: "1em" }}>Please try again later.</p>
      <p>
        If this message keeps popping up please contact us at{" "}
        <Link to="mailto:support@thebossbrief.com">
          support@thebossbrief.com
        </Link>
      </p>
    </div>
  );
};

export default AppHadError;
