import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { campaigns, combo } from "../../utils/quiz_page_rotation";
import PlaybookFooter from "../../components/Playbook/PlaybookFooter";
import QuizLandingHero from "../../components/BizQuiz/QuizLandingHero";

const BizQuiz = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [version, setVersion] = useState("auto");
  const [campaign, setCampaign] = useState("de");
  const [start, setStart] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const param1 = searchParams.get("ve");
    const param2 = searchParams.get("camp");
    const param3 = searchParams.get("start");
    if (param1 && Object.keys(combo).includes(param1)) {
      setVersion(param1);
    } else {
      setVersion("auto");
    }
    if (param2 && Object.keys(campaigns).includes(param2)) {
      setCampaign(param2);
    } else {
      setCampaign("de");
    }
    if (param3) {
      setStart(true);
    }

    setLoading(false);
  }, []);

  if (loading) return null;
  return (
    <div style={{ position: "absolute", left: "0", width: "100%" }}>
      <Container fluid="lg">
        <QuizLandingHero version={version} campaign={campaign} start={start} />
      </Container>
      {/* <div style={{ height: "100px" }} />
      <PlaybookFooter /> */}
    </div>
  );
};

export default BizQuiz;
